import "./index.scss";
import "./assets/scss/Custom.scss";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import useGlobalState from "./context/useGlobalState";
import Context from "./context/context";
 
const Index = () => {
	const store = useGlobalState();
	return (
		<React.StrictMode>
			<Context.Provider value={store}>
				<App />
			</Context.Provider>
		</React.StrictMode>
	);
};

ReactDOM.render(<Index />, document.getElementById("root"));

reportWebVitals();
