import React, { useState, useEffect } from 'react';
import { Form } from "react-bootstrap";
import { State } from '../../types/State';
import { Municipality } from '../../types/Municipality';
import { Decrypt } from '../../utils/SessionCrypto/Crypto';

interface Props {
    index: number;
    defaultState: number;
    defaultMunicipality: string;

    onStateChange: (state: State | undefined, index: number) => void;
    onMunicipalityChange: (municipality: Municipality | undefined, index: number) => void;
    setLoading: (loading: boolean) => void;
}

const StateListData: React.FC<Props> = ({ index, defaultState, defaultMunicipality, onStateChange, onMunicipalityChange, setLoading }) => {
    const [regiones, setRegiones] = useState<State[]>([]);
    const [region, setRegion] = useState<State>();
    const [comunas, setComunas] = useState<Municipality[]>([]);
    const [comuna, setComuna] = useState<Municipality>();
    const [regionLoading, setRegionLoading] = useState(false);
    const [comunaLoading, setComunaLoading] = useState(false);

    //Carga las regiones
    useEffect(() => {
        const getStates = async () => {
            try {
                setRegionLoading(true);
                var response = Decrypt("states");
                setRegiones(response);

                if (defaultState !== 0) {
                    setRegion(response.find((x: { Id: number; }) => x.Id == defaultState));
                }
            } catch (error) {
                console.error('Error fetching states:', error);
            } finally {
                setRegionLoading(false);
            }
        };
        getStates();
    }, [defaultState]);

    //Carga las comunas
    useEffect(() => {
        const selectDefaultState = async () => {
            try {
                setComunaLoading(true);

                if (region !== undefined) {
                    setComunas(region.municipalities);

                    if (defaultMunicipality !== "0") {
                        setComuna(region.municipalities.find((x: { Code: string; }) => x.Code === defaultMunicipality));
                    }
                }
            } catch (error) {
                console.error('Error select default state:', error);
            } finally {
                setComunaLoading(false);
            }
        };
        selectDefaultState();
    }, [region]);

    return (
        <>
            <div className="row mb-3">
                <Form.Label htmlFor="state" className="col-sm-3 col-form-label">Región</Form.Label>
                <div className="col-sm-9">
                    <div className="input-group">
                        <Form.Control
                            name="state"
                            as="select"
                            value={region?.Id}
                            isInvalid={region == undefined || region.Id === 0}
                            isValid={region != undefined && region.Id !== 0}
                            onChange={(e) => {
                                try {
                                    setLoading(true);
                                    var response = regiones.find(item => item.Id === parseInt(e.currentTarget.value));
                                    setRegion(response);
                                    onStateChange(response, index);
                                } catch (error) {
                                    console.error('Error while handle region change:', error);
                                } finally {
                                    setLoading(false);
                                }
                            }}
                        >
                            <option value={0}>Seleccione una región</option>
                            {regiones.map((item) => (<option key={item.Id} value={item.Id}>{item.Name}</option>))}
                        </Form.Control>
                        {regionLoading && (
                            <span className="input-group-text">
                                <i className="spinner-border text-success spinner-border-sm"></i>
                            </span>
                        )}
                        <Form.Control.Feedback type="invalid">
                            Por favor, seleccione una región.
                        </Form.Control.Feedback>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <Form.Label htmlFor="comunaempleador" className="col-sm-3 col-form-label">Comuna</Form.Label>
                <div className="col-sm-9">
                    <div className="input-group">
                        <Form.Control
                            as="select"
                            name="comunaempleador"
                            value={comuna?.Code}
                            isInvalid={comuna === undefined || comuna.Code === ""}
                            isValid={comuna !== undefined && comuna.Code !== ""}
                            onChange={(e) => {
                                try {
                                    setLoading(true);
                                    var response = comunas.find(item => item.Code === e.currentTarget.value);
                                    setComuna(response);
                                    onMunicipalityChange(response, index);
                                } catch (error) {
                                    console.error('Error while handle comuna change:', error);
                                } finally {
                                    setLoading(false);
                                }
                            }}
                        >
                            <option key="0" value="">Seleccionar una comuna</option>
                            {comunas
                                .sort((a, b) => a.Name.localeCompare(b.Name)) // Ordenar por Name alfabéticamente
                                .map((opcion) => (
                                    <option key={opcion.Id} value={opcion.Code}>
                                        {opcion.Name}
                                    </option>
                                ))
                            }
                        </Form.Control>
                        {comunaLoading && (
                            <span className="input-group-text">
                                <i className="spinner-border text-success spinner-border-sm"></i>
                            </span>
                        )}
                        <Form.Control.Feedback type="invalid">
                            Por favor, seleccione una comuna.
                        </Form.Control.Feedback>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StateListData;
