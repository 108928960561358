export default function Boxes({ data }) {
    return (
        <div className="row">
            {data.map((item, index) => (
                <div key={index} className="col-sm-12 col-md-6 col-lg-4 mb-3">
                    <div className="fondo-box">
                        <div
                            className="fondo-box-header text-center"
                            style={{ border: `1px solid ${item.color}` }}
                        >
                            <span className="fondo-titulo" style={{ color: item.color }}>
                                Fondo
                            </span>
                            <span className="fondo-letra" style={{ color: item.color }}>
                                {item.fondo}
                            </span>
                            <p className="fondo-riesgo">
                                {item.riesgo}
                            </p>
                        </div>
                        <div
                            className="fondo-box-body"
                            style={{ backgroundColor: item.color }}
                        >
                            <p>{item.detalle}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
