import { getImage, convertToMoney, getTipoCuenta } from "../../utils/helper/utils";

export default function Cuenta({ cuenta }) {

	return cuenta.seleccionado ? (
		<>
			<div className="account mt-3" key={`cuentas`} id={`cuentas`}>
				<div className="row">
					<div className="col-8 account-type">
						<p className="account-code">{getTipoCuenta(cuenta.tipo)}</p>
						<p className="text-small">Destino: <strong>AFP Modelo</strong></p>
						<p className="text-small">Origen: <strong>{cuenta.origen}</strong></p>
						<p className="text-small">Saldo Total: <strong>${convertToMoney(cuenta.saldo)}</strong></p>
						{cuenta.Porcentaje > 0 && (
							<p className="text-small">Traspaso {cuenta.tipoTraspaso}:{" "}<strong>{cuenta.Porcentaje}%</strong></p>
						)}
						{cuenta.suscribeMandato && (
							<p className="text-small">Monto de descuento mensual: <strong>${convertToMoney(cuenta.saldoRegimen)} ($)</strong></p>
						)}
					</div>
					<div className="col-4 account-icon">
						<div className="row">
							{cuenta.fondos.map((fondo, index) => (
								<div className="col-5" key={`fondo-p-${index}-${index}`} id={`fondo-p-${index}-${index}`}>
									<img src={getImage(fondo.nombre, true)} width="39" height="39" alt={fondo.nombre} />
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	) : null;
}