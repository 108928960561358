import "./AuthenticationPage.scss";
import { useContext, useState, useEffect } from "react";
import { Card, Accordion } from "react-bootstrap";
import { SendRequest } from "../../services/RequestHandler";
import Swal from "sweetalert2";
import Context from "../../context/context";
import registerStep from "../../services/registerStep";
import ConfigManager from "../../settings/ConfigManager";
import images from "../../assets/images";
import Disclaimer from "../../components/Disclaimer/Disclaimer";
import FormClaveUnica from "./FormClaveUnica";
import FormClaveSeguridad from "./FormClaveSeguridad";

export default function AuthenticationPage() {
	const [activeKey, setActiveKey] = useState("0");
	const { state, actions } = useContext(Context);
	const [enableCU, setEnableCU] = useState(true);
	const message = !ConfigManager.ACTIVE_FAO
		? "Si sólo posees cuenta obligatoria podrás utilizar Clave Única como método de autenticación."
		: "Te invitamos a validar tu identidad con Clave Única y responder correctamente las preguntas de seguridad.";

	useEffect(() => {
		const fetchConfiguration = async () => {
			try {
				setLoading(true);
				const result = await SendRequest({
					url: `${ConfigManager.API_BACK_URL}/api/configuration`,
					method: "GET",
					body: null,
				});

				if (result.code === 0) {
					registerStep(2);
					setEnableCU(result.data.cuVisible);
				}
			} catch (error) {
				console.error('Error fetching configuration:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchConfiguration();
	}, []);

	const handleSelect = (key) => {
		setActiveKey(prevKey => key === null ? (prevKey === "0" ? "1" : "0") : key);
	};

	const setLoading = async (isLoading) => {
		actions({ type: "setState", payload: { ...state, isLoading } });
	};

	return (
		<div className="container mt-2 mb-4">
			<div className="row">
				<div className="col-sm-12 col-lg-4">
					<div className="row">
						<div className="col-12">
							<h2 className="mt-3 text-center">
								¡Cámbiate a AFP Modelo!
							</h2>
						</div>
						<div className="col-12 text-center">
							<span>Haz tu solicitud de <strong> cambio online fácil y rápido.</strong></span>
						</div>
						<div className="col-12 d-grid gap-2">
							<div className="mx-auto" style={{ width: "200px" }}>
								<img src={images.cerditoMobile} className="d-block d-lg-none" width={150} alt="mobile" />
							</div>
						</div>
						<div className="mt-3 offset-lg-1 col-lg-10">
							<Accordion activeKey={activeKey} onSelect={handleSelect}>
								{enableCU && (
									<Card className="mb-3">
										<Accordion.Toggle className="collapse-accordion" eventKey="0">
											<Card.Header>
												<div className="row">
													<div className="col-10">
														<img src={images.claveUnica} alt="Clave Única" width={250} />
													</div>
													<div className="col-2 text-right">
														<img src={activeKey === "0" ? images.up : images.down} alt="arrow" width="20" />
													</div>
												</div>
											</Card.Header>
											<div className="row mt-3">
												<div className="col-12">
													<p className="texto-caja">
														{message}
													</p>
												</div>
											</div>
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="0">
											<Card.Body>
												<FormClaveUnica setLoading={setLoading} />
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								)}

								<Card>
									<Accordion.Toggle className="collapse-accordion" eventKey="1">
										<Card.Header>
											<div className="row">
												<div className="col-2">
													<img src={images.key} alt="key" className="identificacion-icon" />
												</div>
												<div className="col-8 text-center">
													<h5>Clave de seguridad</h5>
												</div>
												<div className="col-2 text-right">
													<img src={activeKey === "1" ? images.up : images.down} alt="arrow" width="20" />
												</div>
											</div>
										</Card.Header>
										<div className="row mt-3">
											<div className="col-12">
												<p className="texto-caja">
													Es la segunda Clave de AFP, te permite hacer Cambio de fondos y <strong>cambio de AFP.</strong>
												</p>
											</div>
										</div>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="1">
										<Card.Body className="bg-white">
											<FormClaveSeguridad setLoading={setLoading} />
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							</Accordion>
						</div>
						<div className="col-12">
							<p className="mt-3 text-center text-small">
								Te sugerimos que tengas los datos de tu empleador y cédula
								de identidad a mano para hacer este cambio mucho más
								rápido.
							</p>
						</div>
					</div>
				</div>
				<div className="col-sm-12 col-lg-4 mt-5 d-grid gap-2">
					<div className="mx-auto" style={{ width: "300px" }}>
						<img src={images.cerditoDesktop} className="d-none d-lg-block" width={300} alt="desktop" />
					</div>
				</div>
				<div className="col-sm-12 col-lg-4 mt-5 d-none d-lg-block">
					<Disclaimer />
				</div>
			</div>
		</div >
	);
}