import React, { useState, useEffect } from 'react';
import NationalityList from './NationalityList';
import { NationalityC } from '../../types/NationalityC';
import { SendRequest } from '../../services/RequestHandler';
import { mapToNationalities } from './utils';
import ConfigManager from '../../settings/ConfigManager';

// Define the props for the component
interface Props {
    defaultNationality: string;
    onNationalityChange: (nationality: NationalityC | undefined) => void;
}

const NationalityListData: React.FC<Props> = ({ defaultNationality, onNationalityChange }) => {
    const [nationalities, setNationalities] = useState<NationalityC[]>([]);

    useEffect(() => {
        const fetchNationalities = async () => {
            try {
                const result = await SendRequest({
                    url: `${ConfigManager.API_BACK_URL}/api/nacionalidad`,
                    method: "GET"
                });
                
                if (result.code === 0) {
                    const nationalities: NationalityC[] = mapToNationalities(result.data);
                    setNationalities(nationalities);
                }
            } catch (error) {
                console.error('Error fetching nationalities:', error);
            }
        };

        fetchNationalities();
    }, []);

    const handleNationalityChange = (nationality: NationalityC | undefined) => {
        onNationalityChange(nationality);
    };

    return <NationalityList
        defaultNationality={defaultNationality}
        nationalities={nationalities}
        onNationalityChange={handleNationalityChange} />;
};

export default NationalityListData;
