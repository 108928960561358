import { useState } from "react";
import { Card, Accordion } from "react-bootstrap";
import images from "../../assets/images";
import Cuentas from "./Cuentas";
import AntecedentesLaborales from "./AntecedentesLaborales";
import DatosContacto from "./DatosContacto";
import DatosPersonales from "./DatosPersonales";

export default function AccordionConfirmar({ datos }) {
	const [activeKey, setActiveKey] = useState("0");

	return (
		<>
			<Accordion className="mt-3 mb-3" id="accordionExample" activeKey={activeKey} onSelect={setActiveKey}>
				<Card className="mb-3">
					<Accordion.Toggle className="collapse-accordion" eventKey="0" id="cuentas">
						<Card.Header className="mb-0">
							<div className="row">
								<div className="col-10">
									<h5>Cuentas</h5>
								</div>
								<div className="col-2 text-right">
									<img src={activeKey === "0" ? images.up : images.down} alt="arrow" width="20" />
								</div>
							</div>
						</Card.Header>
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="0" aria-labelledby="cuentas" data-parent="#accordionExample">
						<Card.Body>
							<Cuentas cuentas={datos.Cuentas} />
						</Card.Body>
					</Accordion.Collapse>
				</Card>
				<Card className="mb-3">
					<Accordion.Toggle className="collapse-accordion" eventKey="1" id="personal">
						<Card.Header className="mb-0">
							<div className="row">
								<div className="col-10">
									<h5>Datos personales</h5>
								</div>
								<div className="col-2 text-right">
									<img src={activeKey === "1" ? images.up : images.down} alt="arrow" width="20" />
								</div>
							</div>
						</Card.Header>
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="1" aria-labelledby="personal" data-parent="#accordionExample">
						<Card.Body>
							<DatosPersonales data={datos.Datos} />
						</Card.Body>
					</Accordion.Collapse>
				</Card>
				<Card className="mb-3">
					<Accordion.Toggle className="collapse-accordion" eventKey="2" id="contacto">
						<Card.Header className="mb-0">
							<div className="row">
								<div className="col-10">
									<h5>Datos de contacto</h5>
								</div>
								<div className="col-2 text-right">
									<img src={activeKey === "2" ? images.up : images.down} alt="arrow" width="20" />
								</div>
							</div>
						</Card.Header>
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="2" aria-labelledby="contacto" data-parent="#accordionExample">
						<Card.Body>
							<DatosContacto data={datos.Datos} />
						</Card.Body>
					</Accordion.Collapse>
				</Card>
				<Card className="mb-3">
					<Accordion.Toggle className="collapse-accordion" eventKey="3" id="antecedentes">
						<Card.Header className="mb-0">
							<div className="row">
								<div className="col-10">
									<h5>Antecedentes laborales</h5>
								</div>
								<div className="col-2 text-right">
									<img src={activeKey === "3" ? images.up : images.down} alt="arrow" width="20" />
								</div>
							</div>
						</Card.Header>
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="3" aria-labelledby="antecedentes" data-parent="#accordionExample">
						<Card.Body>
							<AntecedentesLaborales datos={datos.Datos} />
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
		</>
	);
}