import { useState } from "react";
import { Switch } from "@mui/material";

export default function Mandato({ cuenta, onCallback }) {

    const [suscribe, setSuscribe] = useState(false);

    const style = {
        borderColor: "#ccc",
        borderRadius: ".25rem",
        padding: ".375rem .75rem",
        border: "1px solid #ccc",
        width: "300px",
    };

    const onChangeAPV = (e) => {
        const checked = e.currentTarget.checked;

        setSuscribe(checked);
    }

    const onChangeMontoAPV = (e) => {

    }

    return (
        <>
            <div className="row">
                <div className="col-md-10">
                    <h3>Quiero suscribir un mandato de descuento para mi Cuenta Voluntaria con mi empleador.</h3>
                </div>
                <div className="col-md-2">
                    <Switch name="checkedA" onChange={onChangeAPV} />
                </div>
            </div>
            {suscribe && (
                <div className="row">
                    <div className="col-md-6">
                        Monto de descuento mensual($)
                    </div>
                    <div className="col-md-6">
                        <input type="text" style={style} placeholder="Ingrese el monto en pesos: $10.000" onChange={onChangeMontoAPV} />
                    </div>
                </div>
            )}
        </>
    );
}