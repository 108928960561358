import React, { useState, useEffect, ChangeEvent } from "react";
import { Form } from "react-bootstrap";
import Inputmask from "inputmask";
import validator from "validator";
import { isValidIdentifier } from "../../utils/helper/utils";

interface IdentifierProps {
    validateForm: { rut: boolean };
    setValidateForm: (validateForm: { rut: boolean }) => void;
    onChangeCallback?: (identifier: string, index: number) => void;
    initialValue: string;
    index: number;
}

interface ErrorState {
    isValid: boolean;
    message: string;
}

const Identifier: React.FC<IdentifierProps> = (props) => {
    const [identifier, setIdentifier] = useState<string>("");
    const [error, setError] = useState<ErrorState>({ isValid: false, message: "" });

    useEffect(() => {

        if (props.initialValue) {
            onChanges(props.initialValue);
        }

        const inputElements = document.querySelectorAll<HTMLInputElement>('[id^="identifier_"]');

        if (inputElements) {
            inputElements.forEach(element => {
                Inputmask({
                    mask: ["9[9].999.999-K", "99.999.999-K"],
                    autoUnmask: true,
                    showMaskOnFocus: false,
                    showMaskOnHover: false,
                    definitions: {
                        K: {
                            validator: "[0-9Kk]",
                            casing: "upper",
                        },
                    },
                }).mask(element);
            });
        }
    }, []);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        onChanges(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.ctrlKey && event.key === 'v') {
            event.preventDefault();
        }
        if (event.metaKey && event.key === 'v') {
            event.preventDefault();
        }
    };

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (event.target.value === "") {
            onChanges("")
        }
    };

    const onChanges = (value: string) => {

        let errorMessage = "";
        let isValid = true;

        if (validator.isEmpty(value)) {
            errorMessage = "Por favor, ingrese el RUT.";
            isValid = false;
            value = "";
        } else if (!isValidIdentifier(value)) {
            errorMessage = "El RUT ingresado es incorrecto.";
            isValid = false;
        } else {
            errorMessage = "El RUT es correcto.";
        }

        setError({ isValid, message: errorMessage });

        if (props.setValidateForm) {
            props.setValidateForm({ ...props.validateForm, rut: isValid });
        }
        if (props.onChangeCallback) {
            props.onChangeCallback(value, props.index);
        }

        setIdentifier(value);
    };

    const onPaste = (event: React.MouseEvent<HTMLInputElement>) => {        
        event.preventDefault();
    }

    return (
        <>
            <Form.Control
                id={`identifier_${props.index}`}
                name="identifier"
                type="text"
                placeholder="99.999.999-K"
                value={identifier}
                isInvalid={!error.isValid && error.message !== "" || props.initialValue === ""}
                isValid={error.isValid}
                maxLength={12}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                onBlur={handleOnBlur}
                autoComplete="off"
                onContextMenu={onPaste}
            />
            <Form.Control.Feedback type={error.isValid ? "valid" : "invalid"}>
                {error.message}
            </Form.Control.Feedback>
        </>
    );
};

export default Identifier;

