// Importación de imágenes SVG
import arrowRight from "./svg/arrow-right.svg";
import arrowDown from "./svg/arrow.svg";
import bg from "./svg/bg-blue.svg";
import cerditoDesktop from "./svg/desktop.svg";
import cerditoMobile from "./svg/mobile.svg";
import chat from "./svg/chat.svg";
import claveUnica from "./svg/clave-unica.svg";
import clock from "./svg/clock-regular.svg";
import close from "./svg/close-circle.svg";
import correct from "./svg/incorrecta.svg";
import down from "./svg/arrow-down.svg";
import eye from "./svg/eye-regular.svg";
import eyeSlash from "./svg/eye-slash-regular.svg";
import face from "./svg/face.svg";
import girl from "./svg/face-nina.svg";
import heart from "./svg/heart.svg";
import heartCircle from "./svg/heart-circle.svg";
import key from "./svg/green-key.svg";
import like from "./svg/like.svg";
import mantein from "./svg/mantein.svg";
import nina from "./svg/nina.svg";
import stepOne from "./svg/paso1.svg";
import stepTwo from "./svg/paso2.svg";
import stepThree from "./svg/paso3.svg";
import question from "./svg/pregunta.svg";
import star from "./svg/star.svg";
import timer from "./svg/timer.svg";
import twitter from "./svg/twitter.svg";
import instagram from "./svg/instagram.svg";
import facebook from "./svg/facebook.svg";
import logo from "./svg/logo.svg";
import blanco from "./svg/modelo-blanco.svg";
import aumenta from "./svg/aumenta.svg";
import up from "./svg/arrow-up.svg";
import cedula from "./svg/cedula.svg";
import edit from "./svg/edit.svg";
import erase from "./svg/delete.svg";
import alert from "./svg/alert.svg";
import ticket from "./svg/ticket.svg";
import token from "./svg/token.svg";

// Importación de imágenes PNG
import A from "./png/a.png";
import A_color from "./png/a-color.png";
import B from "./png/b.png";
import B_color from "./png/b-color.png";
import C from "./png/c.png";
import C_color from "./png/c-color.png";
import D from "./png/d.png";
import D_color from "./png/d-color.png";
import E from "./png/e.png";
import E_color from "./png/e-color.png";

// Importación de imágenes GIF
import loading from "./gif/ellipsis.gif";

// Agrupación de imágenes en un objeto
const images = {
    alert,
    // PNG
    A,
    A_color,
    B,
    B_color,
    C,
    C_color,
    D,
    D_color,
    E,
    E_color,

    // GIF
    loading,

    // SVG
    arrowRight,
    arrowDown,
    aumenta,
    bg,
    cerditoDesktop,
    cerditoMobile,
    cedula,
    chat,
    claveUnica,
    clock,
    close,
    correct,
    down,
    up,
    eye,
    eyeSlash,
    face,
    girl,
    heart,
    heartCircle,
    key,
    like,
    mantein,
    nina,
    stepOne,
    stepTwo,
    stepThree,
    question,
    star,
    timer,
    twitter,
    instagram,
    facebook,
    logo,
    blanco,
    edit,
    erase,
    ticket,
    token
};

export default images;
