import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { formatIdentifier } from "../../utils/helper/utils";

export default function FichaConfirmaEmpleador({ data }) {
    const [identifier, seIdentifier] = useState("");
    
    useEffect(() => {
        seIdentifier(formatIdentifier(data.rutempleador));
    }, [data.rutempleador]);

    return (
        <fieldset>
            <div className="row mb-3">
                <Form.Label htmlFor="tipoempleador" className="col-sm-3 col-form-label">Razón Social</Form.Label>
                <div className="col-sm-9">
                    <Form.Control type="text" className="form-control-plaintext text-uppercase" id="tipoempleador" value={data.razonSocialempleador} readOnly />
                </div>
            </div>
            <div className="row mb-3">
                <Form.Label htmlFor="rut" className="col-sm-3 col-form-label">Rut</Form.Label>
                <div className="col-sm-9">
                    <Form.Control type="text" className="form-control-plaintext text-uppercase" id="rut" value={identifier} readOnly />
                </div>
            </div>
            <div className="row mb-3">
                <Form.Label htmlFor="region" className="col-sm-3 col-form-label">Región</Form.Label>
                <div className="col-sm-9">
                    <Form.Control type="text" className="form-control-plaintext text-uppercase" id="region" value={data.nombreRegion} readOnly />
                </div>
            </div>
            <div className="row mb-3">
                <Form.Label htmlFor="comuna" className="col-sm-3 col-form-label">Comuna</Form.Label>
                <div className="col-sm-9">
                    <Form.Control type="text" className="form-control-plaintext text-uppercase" id="comuna" value={data.nombreComuna} readOnly />
                </div>
            </div>
            <div className="row mb-3">
                <Form.Label htmlFor="Dirección" className="col-sm-3 col-form-label">Dirección</Form.Label>
                <div className="col-sm-9">
                    <Form.Control type="text" className="form-control-plaintext text-uppercase" id="Dirección" value={data.direccionempleador} readOnly />
                </div>
            </div>
            <div className="row mb-3">
                <Form.Label htmlFor="Email" className="col-sm-3 col-form-label">Email</Form.Label>
                <div className="col-sm-9">
                    <Form.Control type="text" className="form-control-plaintext text-uppercase" id="Email" value={data.emailNotifiacionempleador} readOnly />
                </div>
            </div>
            <div className="row mb-3">
                <Form.Label htmlFor="Teléfono" className="col-sm-3 col-form-label">Teléfono</Form.Label>
                <div className="col-sm-9">
                    <Form.Control type="text" className="form-control-plaintext text-uppercase" id="Teléfono" value={`+56${data.telefonoempleador}`} readOnly />
                </div>
            </div>
            <hr className="hr" />
        </fieldset>
    );
}
