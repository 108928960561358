import { useState, useCallback } from 'react';

const useGlobalState = () => {
    // Define el estado inicial
    const [state, setState] = useState({ isLoading: false });
    //const [authenticated, setAuthenticated] = useState({ isAuthenticated: false });

    const [authenticated, setAuthenticated] = useState(() => {
        const saved = sessionStorage.getItem('authenticated');
        return saved ? JSON.parse(saved) : { isAuthenticated: false };
    });

    // Usa useCallback para memorizar las funciones de acción
    const setStateAction = useCallback((payload) => {
        setState(prevState => ({ ...prevState, ...payload }));
    }, []);

    const setAuthenticatedAction = useCallback((payload) => {
        setAuthenticated(payload);
        //console.log(payload);
        sessionStorage.setItem('authenticated', JSON.stringify(payload));
    }, []);

    // Define una función para manejar las acciones
    const actions = useCallback((action) => {
        const { type, payload } = action;
        switch (type) {
            case 'setState':
                setStateAction(payload);
                break;
            case 'setAuthenticated':
                setAuthenticatedAction(payload);
                break;
            default:
                console.warn(`Action type ${type} not recognized`);
        }
    }, [setStateAction, setAuthenticatedAction]);

    return { state, authenticated, actions };
}

export default useGlobalState;
