import { Form } from "react-bootstrap";
import { NationalityC } from "../../types/NationalityC";
import { NationalityListData } from "../../components/Nationality";
import { State } from "../../types/State";
import { Municipality } from "../../types/Municipality";
import { StateListData } from "../../components/State";
import validator from "validator";
import { cleanInput } from "../../utils/helper/utils";
import Tooltip from "../../components/Tooltip/Tooltip";
import ConfigManager from "../../settings/ConfigManager";

interface ContactFormProps {
    datos: any;
    setDatos: React.Dispatch<React.SetStateAction<any>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContactForm: React.FC<ContactFormProps> = ({ datos, setDatos, setLoading }) => {

    const phoneIsInvalid = (telefono: string): boolean => {
        const re = /^\d{9}$/;
        return !re.test(telefono);
    };

    const onchangeStates = async (item: State | undefined, index: number) => {
        try {
            setDatos({
                ...datos,
                Region: item?.Id,
                NombreRegion: item?.Name,
                Comuna: "",
                NombreComuna: ""
            });
        } catch (error) {
            console.error('Error while changing states:', error);
        }
    }

    const onchangeMunicipalities = (item: Municipality | undefined, index: number) => {
        setDatos({
            ...datos,
            Comuna: item?.Code,
            NombreComuna: item?.Name
        });
    }

    const onchangeNationality = (nationality: NationalityC | undefined) => {
        try {
            setDatos({
                ...datos,
                Nacionalidad: nationality?.Code,
                NombreNacionalidad: nationality?.Name
            });
        } catch (error) {
            console.error('Error while changing nationalities:', error);
        }
    };

    return (
        <div className="container-data">
            <div className="container-data-type">
                <fieldset>
                    <legend>Datos de contacto</legend>
                    <div className="row mb-3">
                        <Form.Label htmlFor="email" className="col-sm-3 col-form-label">Correo electrónico</Form.Label>
                        <div className="col-sm-9">
                            <Form.Control
                                name="email"
                                type="email"
                                placeholder="Correo electrónico"
                                value={datos.Email}
                                isInvalid={!validator.isEmail(datos.Email)}
                                isValid={validator.isEmail(datos.Email)}
                                onChange={(e) => setDatos({ ...datos, Email: e.target.value })}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, ingrese una dirección de correo electrónico válida.
                            </Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <Form.Label htmlFor="phone" className="col-sm-3 col-form-label">Teléfono</Form.Label>
                        <div className="col-sm-9">
                            <div className="input-group">
                                <span className="input-group-text">+56</span>
                                <Form.Control
                                    name="phone"
                                    maxLength={9}
                                    type="tel"
                                    placeholder="Teléfono"
                                    value={datos.Telefono}
                                    isInvalid={phoneIsInvalid(datos.Telefono)}
                                    isValid={!phoneIsInvalid(datos.Telefono)}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^[0-9\b]*$/.test(value)) {
                                            setDatos({ ...datos, Telefono: value });
                                        }
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {datos.Telefono.length === 0
                                        ? "Por favor, ingrese un número de teléfono."
                                        : "Por favor, ingrese un número de teléfono válido (9 dígitos)."}
                                </Form.Control.Feedback>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <Form.Label htmlFor="address" className="col-sm-3 col-form-label">Dirección particular</Form.Label>
                        <div className="col-sm-9">
                            <Form.Control
                                name="address"
                                type="text"
                                placeholder="Dirección particular"
                                value={cleanInput(datos.Direccion)}
                                isInvalid={datos.Direccion === ""}
                                isValid={datos.Direccion !== ""}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^[-'A-Za-zÀ-ÖØ-öø-ÿ0-9\s]*$/.test(value)) {
                                        setDatos({ ...datos, Direccion: cleanInput(value) });
                                    }
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, ingrese una dirección particular válida.
                            </Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="">
                        <StateListData
                            index={0}
                            defaultState={datos.Region}
                            defaultMunicipality={datos.Comuna}
                            onStateChange={onchangeStates}
                            onMunicipalityChange={onchangeMunicipalities}
                            setLoading={setLoading}
                        />
                    </div>
                    <div className="row mb-3">
                        <NationalityListData defaultNationality={datos.Nacionalidad} onNationalityChange={onchangeNationality} />
                    </div>
                    <div className="mb-3">
                        <div className="form-check col-sm-12 col-md-12 offset-lg-3 col-lg-9">
                            <input
                                id="defaultCheck1"
                                className="form-check-input "
                                type="checkbox"
                                checked={datos.Cartola}
                                onChange={(e) => setDatos({ ...datos, Cartola: e.target.checked })}
                            />
                            <Form.Label className="form-check-label" htmlFor="defaultCheck1">
                                <div className="row">
                                    <div className="col-sm-11 col-md-11 col-lg-11">
                                        Deseo recibir mi cartola cuatrimestral por correo
                                    </div>
                                    <div className="col-sm-1 col-md-1 col-lg-1">
                                        <Tooltip text="Si desmarcas esta opción, te enviaremos tu cartola por carta a tu dirección particular." image={undefined} />
                                    </div>
                                </div>
                            </Form.Label>
                        </div>
                    </div>
                </fieldset>
                {ConfigManager.SHOW_MARKUP && (
                    <div className="alert alert-danger" role="alert">
                        <pre>{JSON.stringify(
                            {
                                Prospecto: datos.Prospecto,
                                Nombre: datos.Nombre,
                                ApellidoPaterno: datos.ApellidoPaterno,
                                ApellidoMaterno: datos.ApellidoMaterno,
                                Rut: datos.Rut,
                                FechaNacimiento: datos.FechaNacimiento,
                                Nacionalidad: datos.Nacionalidad,
                                NombreNacionalidad: datos.NombreNacionalidad,
                                Email: datos.Email,
                                Telefono: datos.Telefono,
                                Direccion: datos.Direccion,
                                Region: datos.Region,
                                NombreRegion: datos.NombreRegion,
                                Comuna: datos.Comuna,
                                NombreComuna: datos.NombreComuna,
                                Cartola: datos.Cartola,
                                Sexo: datos.Sexo,
                                TipoEmpleado: datos.TipoEmpleado
                            },
                            null,
                            "\t"
                        )}</pre>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ContactForm;
