import "./Loading.scss";
import Spinner from "react-bootstrap/Spinner";

export default function Loading() {
    return (
        <div className="loading">
            <Spinner animation="border" role="status" variant="success" style={{ width: "5rem", height: "5rem" }}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}