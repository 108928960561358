import { Form } from "react-bootstrap";

export default function DatosDeContacto({ data }) {
    return (
        <fieldset>
            <div className="row mb-3">
                <Form.Label htmlFor="email" className="col-sm-3 col-form-label">Email</Form.Label>
                <div className="col-sm-9">
                    <Form.Control type="text" className="form-control-plaintext text-uppercase" id="email" value={data.Email} readOnly />
                </div>
            </div>
            <div className="row mb-3">
                <Form.Label htmlFor="phone" className="col-sm-3 col-form-label">Teléfono</Form.Label >
                <div className="col-sm-9">
                    <Form.Control type="text" className="form-control-plaintext text-uppercase" id="phone" value={`+56${data.Telefono}`} readOnly />
                </div>
            </div>
            <div className="row mb-3">
                <Form.Label htmlFor="address" className="col-sm-3 col-form-label">Dirección</Form.Label >
                <div className="col-sm-9">
                    <Form.Control type="text" className="form-control-plaintext text-uppercase" id="address" value={data.Direccion} readOnly />
                </div>
            </div>
            <div className="row mb-3">
                <Form.Label htmlFor="region" className="col-sm-3 col-form-label">Región</Form.Label >
                <div className="col-sm-9">
                    <Form.Control type="text" className="form-control-plaintext text-uppercase" id="region" value={data.NombreRegion} readOnly />
                </div>
            </div>
            <div className="row mb-3">
                <Form.Label htmlFor="comuna" className="col-sm-3 col-form-label">Comuna</Form.Label >
                <div className="col-sm-9">
                    <Form.Control type="text" className="form-control-plaintext text-uppercase" id="comuna" value={data.NombreComuna} readOnly />
                </div>
            </div>
        </fieldset>
    );
}