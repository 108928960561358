import "./HomePage.scss";
import { useState, useEffect, useContext } from "react";
import { isMobile, isBrowser } from 'react-device-detect';
import Formulario from "./HomePageForm";
import Disclaimer from "../../components/Disclaimer/Disclaimer";
import images from "../../assets/images";
import Context from "../../context/context";
import { Encrypt } from "../../utils/SessionCrypto/Crypto";

export default function HomePage() {
	const { authenticated, actions } = useContext(Context);
	const [device] = useState(isMobile ? "Mobile" : isBrowser ? "Browser" : "Unknown");
	const [ip, setIp] = useState('');

	useEffect(() => {
		const fetchIp = async () => {
			try {
				const response = await fetch('https://api.ipify.org?format=json', { method: "GET" });
				const json = await response.json();

				setIp(json.ip);
			} catch (error) {
				console.error('Error fetching IP address:', error);
			}
		};

		fetchIp();
	}, []);

	useEffect(() => {
		sessionStorage.clear();
		localStorage.clear();

		Encrypt("data", { device: device, ip: ip, isAuthenticated: false });

		actions({ type: "setAuthenticated", payload: { ...authenticated, isAuthenticated: false } });
	}, [ip]);

	return (
		<div className="container mt-2 mb-4">
			<div className="row">
				<div className="col-sm-12 col-lg-4">
					<div className="row">
						<div className="col-12">
							<h2 className="mt-3 text-center">
								¡Cámbiate a AFP Modelo!
							</h2>
						</div>
						<div className="col-12 text-center">
							<span>Haz tu solicitud de <strong> cambio online fácil y rápido.</strong></span>
						</div>
						<div className="col-12 d-grid gap-2">
							<div className="mx-auto" style={{ width: "200px" }}>
								<img src={images.cerditoMobile} className="d-block d-lg-none" width={150} alt="mobile" />
							</div>
						</div>
						<div className="mt-3 col-12">
							<Formulario />
						</div>
						<div className="col-12">
							<p className="mt-3 text-center text-small">
								Te sugerimos que tengas los datos de tu empleador y cédula
								de identidad a mano para hacer este cambio mucho más
								rápido.
							</p>
						</div>
					</div>
				</div>
				<div className="col-sm-12 col-lg-4 mt-5 d-grid gap-2">
					<div className="mx-auto" style={{ width: "300px" }}>
						<img src={images.cerditoDesktop} className="d-none d-lg-block" width={300} alt="desktop" />
					</div>
				</div>
				<div className="col-sm-12 col-lg-4 mt-5 d-none d-lg-block">
					<Disclaimer />
				</div>
			</div>
		</div >
	);
}