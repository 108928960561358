import { Fondo } from "../../types/Fondo";
import { Propias } from "../../types/Propias";
import { Saldos } from "../../types/Saldos";
import { getImage } from "../../utils/helper/utils";

export const mapToSingle = (json: any): Saldos => {
    if (json !== null && json !== undefined) {
        return {
            Code: json.code,
            Message: json.message,
            Response: mapToList(json.data)
        };
    }

    return {
        Code: 1,
        Message: "No Data",
        Response: []
    };
};

export const mapToList = (jsonArray: any[]): Propias[] => {
    if (jsonArray !== undefined && jsonArray.length > 0) {
        return jsonArray.map(item => ({
            Producto: item.producto,
            Fondo: item.fondo,
            Fondos: mapToListFondos(item.fondos),
            Image: getImage(item.fondo, true),
            Monto: item.monto,
            Descripcion: item.descripcion,
            Categoria: item.categoria
        }));
    }
    return [];
};

export const mapToListFondos = (jsonArray: any[]): Fondo[] => {
    if (jsonArray !== undefined && jsonArray.length > 0) {
        return jsonArray.map(item => ({
            Imagen: getImage(item, true),
            Origen: item,
            Destino: "",
            Regimenes: [],
            Saldo: 0,
            SaldoCuota: 0,
            ValorCuota: 0,
        }));
    }
    return [];
};