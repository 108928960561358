import Swal from "sweetalert2";
import ConfigManager from "../settings/ConfigManager";
import { Encrypt, Decrypt } from "../utils/SessionCrypto/Crypto";
import { SendRequest } from "./RequestHandler";

const registerStep = async (stageId: number) => {
	// Desencriptar datos
	const data = Decrypt("data");

	// Validar la solicitud
	if (isValidRequest(data.solicitud)) {
		try {
			// Realizar la primera solicitud GET
			const response = await SendRequest({
				url: `${ConfigManager.API_BACK_URL}/api/Traspaso/${data.solicitud}`,
				method: "GET",
			});

			// Verificar el código de respuesta
			if (response.code === 0) {
				// Actualizar datos con el Id del prospecto
				data.Prospecto = response.data.id;
				Encrypt("data", data);

				// Realizar la segunda solicitud POST
				await SendRequest({
					url: `${ConfigManager.API_BACK_URL}/api/Fase`,
					method: "POST",
					body: JSON.stringify({ IdFase: stageId, IdProspecto: response.data.id, Fecha: new Date() })
				});
			}
		} catch (error) {
			// Manejo de errores
			console.error('Error registrando paso:', error);
			Swal.fire({
				icon: "error",
				text: 'Ocurrió un error al registrar el paso.',
				confirmButtonText: "Ok",
				allowOutsideClick: false,
			});
		}
	}
};

// Función para validar la solicitud
const isValidRequest = (request: any) => {
	return request !== null && request !== "" && request !== 0 && request !== undefined;
};

export default registerStep;
