
export default function Disclaimer() {
    return (
        <div className="media-body text-muted mx-auto">
            <p>
                Declaro haberme informado sobre la calidad de servicio de las AFP en la herramienta <a href="https://www.spensiones.cl/infoydec" target="_blank" style={{ color: "#6D6B6B", textDecoration: "underline" }}>lnfórmate y Decide.</a>
            </p>
            <p>
                Declaro conocer la <a href="https://www.afpmodelo.cl/AFP/Cambiate-a-modelo/Tabla_Rentabilidad.aspx" target="_blank" style={{ color: "#6D6B6B", textDecoration: "underline" }}>rentabilidad real y comisiones de los últimos 36 meses de todas la AFP.</a>
            </p>
        </div>
    );
}