import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { NationalityC } from "../../types/NationalityC";

// Define the props for the component
interface Props {
    defaultNationality: string;
    nationalities: NationalityC[];
    onNationalityChange: (nationality: NationalityC | undefined) => void;
}

// Define the NationalityList component
const NationalityList: React.FC<Props> = ({ defaultNationality, nationalities, onNationalityChange }) => {
    const [selectedNationality, setSelectedNationality] = useState<NationalityC | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    // Handler for select change
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        try {
            setLoading(true);

            const value = event.target.value;
            const selected = nationalities.find(n => n.Code === value);
            setSelectedNationality(selected);
            onNationalityChange(selected);
        } catch (error) {
            console.error("Error while changing nationalities:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const selectDefaultNationality = async () => {
            try {
                setLoading(true);
                if (nationalities.length > 0) {
                    var result = nationalities.find(item => item.Code === defaultNationality);
                    setSelectedNationality(result);
                }
            } catch (error) {
                console.error('Error selected default nationality:', error);
            } finally {
                setLoading(false);
            }
        };
        selectDefaultNationality();
    }, [nationalities]);

    return (
        <>
            <Form.Label htmlFor="nationality" className="col-sm-3 col-form-label">Nacionalidad</Form.Label>
            <div className="col-sm-9">
                <div className="input-group">
                    <Form.Control
                        name="nationality"
                        as="select"
                        value={selectedNationality?.Code}
                        isInvalid={selectedNationality?.Code === "" || selectedNationality?.Code === undefined}
                        isValid={selectedNationality?.Code !== ""}
                        onChange={handleChange}
                    >
                        <option value="">Seleccione una nacionalidad</option>
                        {nationalities.map((nationality) => (
                            <option key={nationality.Id} value={nationality.Code}>
                                {nationality.Name}
                            </option>
                        ))}
                    </Form.Control>
                    {selectedNationality !== undefined && selectedNationality.Code !== "" && (
                        <span className="input-group-text">
                            <img src={selectedNationality.Image} alt={selectedNationality?.Code} style={{ width: '32px', height: '24px', marginRight: '0px' }} />
                        </span>
                    )}
                    <Form.Control.Feedback type="invalid">
                        Por favor, seleccione una nacionalidad.
                    </Form.Control.Feedback>
                </div>
            </div>
        </>
    );
};

export default NationalityList;
