
export default function Item({ data, image }) {
    return (
        <div className="media">
            <div className="media-body text-mut row">
                <div className="col-2">
                    <img src={image} className="desktop" width="39" height="39" alt="desktop" />
                </div>
                <div className="col-10">
                    <span><strong className="d-block">{data.head}</strong>{data.body}</span>
                </div>
            </div>
        </div>
    )
}