import "./VoucherPage.scss";
import { useEffect, useContext } from "react";
import { Card } from "react-bootstrap";
import { SendRequest } from "../../services/RequestHandler";
import Button from "../../components/Button/Button";
import Context from "../../context/context";
import { Decrypt } from "../../utils/SessionCrypto/Crypto";
import ConfigManager from "../../settings/ConfigManager";
import images from "../../assets/images";

export default function VoucherPage(props) {
	const { state, actions } = useContext(Context);

	useEffect(() => {
		const fetchData = async () => {
			try {
				await setLoading(true);
				const data = Decrypt("data");
				if (data !== null) {
					await SendRequest({
						url: `${ConfigManager.API_BACK_URL}/api/log/final/${data.run}/${data.autenticacion}/${data.device}`,
						method: 'GET',
					});
				}
			} finally {
				await setLoading(false);
				localStorage.clear();
				sessionStorage.clear();
			}
		};
		fetchData();
	}, []);

	const handleDownload = async (e) => {
		e.preventDefault();
		await setLoading(true);

		try {
			const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
				const byteCharacters = atob(b64Data);
				const byteArrays = [];
				for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
					const slice = byteCharacters.slice(offset, offset + sliceSize);
					const byteNumbers = new Array(slice.length);
					for (let i = 0; i < slice.length; i++) {
						byteNumbers[i] = slice.charCodeAt(i);
					}
					byteArrays.push(new Uint8Array(byteNumbers));
				}
				return new Blob(byteArrays, { type: contentType });
			};

			const blob = b64toBlob(props.location.state.data.file, "application/pdf");
			const blobUrl = URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.style.display = "none";
			a.href = blobUrl;
			a.download = "comprobante.pdf";
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(blobUrl);

		} finally {
			await setLoading(false);
		}
	};

	const setLoading = async (isLoading) => {
		actions({ type: "setState", payload: { ...state, isLoading } });
	};

	return (
		<div className="bg-blue">
			<div className="img-bienvenida">
				<img src={images.like} alt="bienvenida" />
			</div>
			<div className="container-bienvenida mt-3 mb-5">
				<Card className="media-white">
					<h2>¡Bienvenid@!</h2>
					<div className="corazon">
						<img src={images.heartCircle} alt="corazon" />
					</div>
					<p className="mt-2">
						Tu cambio a <strong>AFP Modelo</strong> está en curso.
						<br /> Te enviamos el detalle de esta solicitud a tu correo, con
						información importante.
					</p>
					<p className="mt-2">
						<strong style={{ color: '#0B7AC7' }}>¿Cuánto demora el cambio?</strong>
					</p>
					<p className="mt-2">
						El cambio de AFP no es inmediato.
						<strong style={{ color: '#0B7AC7' }}> Se completará la segunda semana del mes siguiente.</strong>
						Estos plazos están normados por la Superintendencia de Pensiones.
						Después de esta fecha, podrás solicitar tu Clave Web para que
						utilices por completo las plataformas digitales de <strong>AFP Modelo</strong>.
					</p>
					<p>
						<strong style={{ color: '#0B7AC7' }}>
							Recuerda que si necesitas tu certificado de cotizaciones,
							deberás solicitarlo en tu antigua AFP.
						</strong>
					</p>
					<div className="mt-2">
						<Button
							id="B-TW-0011"
							variant="primary"
							onClick={handleDownload}
							loading={state.loading}
							block
						>
							Descargar el Comprobante
						</Button>
						<p className="mt-2">Si tienes dudas, contáctanos</p>
					</div>
					<div className="redes mt-2 mb-2">
						<a href="https://www.facebook.com/afpmodelo" target="_blank" rel="noreferrer">
							<img src={images.facebook} alt="facebook" />
						</a>
						<a href="https://twitter.com/AFPModelo" target="_blank" rel="noreferrer">
							<img src={images.twitter} alt="twitter" />
						</a>
						<a href="https://www.instagram.com/afpmodelocl" target="_blank" rel="noreferrer">
							<img src={images.instagram} alt="instagram" />
						</a>
					</div>
				</Card>
			</div>
		</div>
	);
};