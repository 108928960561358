import Cuenta from "./Cuenta";

const Cuentas = ({ cuentas }) => {
    return (
        <div key="Propias" className="container">
            <div className="row">
                {cuentas.map(cuenta => (<Cuenta key={cuenta.tipo} cuenta={cuenta} />))}
            </div>
        </div>
    );
}

export default Cuentas;
