import { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { SendRequest } from "../../services/RequestHandler";
import Context from "../../context/context";
import { Encrypt, Decrypt } from "../../utils/SessionCrypto/Crypto";
import images from "../../assets/images";
import ConfigManager from "../../settings/ConfigManager";

export default function AffiliateVerificationPage() {
	const { state, actions } = useContext(Context);
	const [toAuthenticationPage, setToAuthenticationPage] = useState(false);

	const agregarOtraCuenta = async () => {
		try {
			await setLoading(true);
			const decrypted = Decrypt("data");

			var result = await SendRequest({
				url: `${ConfigManager.API_URL}/api/Afiliado/Informacion`,
				method: "POST",
				body: JSON.stringify({
					Rut: decrypted.run,
					Device: decrypted.device
				})
			});

			if (result.code === 0) {
				decrypted.name = result.data.nombre;
				decrypted.fatherName = result.data.apPaterno;
				decrypted.motherName = result.data.apMaterno;
				decrypted.phone = result.data.celular;
				decrypted.email = result.data.email;

				const response = await SendRequest({
					url: `${ConfigManager.API_BACK_URL}/api/Traspaso`,
					method: "POST",
					body: JSON.stringify({
						Rut: decrypted.run,
						Nombres: `${result.data.nombre} ${result.data.apPaterno} ${result.data.apMaterno}`,
						Celular: result.data.celular,
						Email: result.data.email,
						Ip: decrypted.ip
					})
				});

				if (response.code === 0) {
					decrypted.solicitud = response.data.token;
				}
				Encrypt("data", decrypted);

				setToAuthenticationPage(true);
			}
		} catch (error) {
			console.error("Error validating:", error);
		} finally {
			await setLoading(false);
		}
	};

	const setLoading = async (isLoading) => {
		actions({ type: "setState", payload: { ...state, isLoading } });
	};

	const onClick = () => {
		window.location.replace("https://www.afpmodelo.cl/AFP/Acceso-mi-cuenta/Acceso-a-mi-Cuenta.aspx");
	}

	if (toAuthenticationPage) {
		return (
			<Redirect to={{ pathname: "/autenticacion" }} />
		);
	}

	return (
		<div className="container mt-3 mb-3 p-3 text-center">
			<div className="row">
				<div className="col-8 offset-2 col-lg-4 offset-lg-4">
					<h4><strong>¡Tú ya eres parte de AFP Modelo!</strong></h4>
				</div>
			</div>
			<div className="row">
				<div className="col-8 offset-2 col-lg-4 offset-lg-4">
					<img src={images.correct} alt="nina modelo" width={150} />
				</div>
			</div>
			<div className="row">
				<div className="col-8 offset-2 col-lg-4 offset-lg-4">
					<Button id="B-TW-0003" variant="primary" onClick={onClick}>
						¿Quieres ir a tu sitio privado?
					</Button>
				</div>
			</div>
			<div className="row">
				<div className="col-8 offset-2 col-lg-4 offset-lg-4">
					<span>o</span>
				</div>
			</div>
			<div className="row">
				<div className="col-8 offset-2 col-lg-4 offset-lg-4">
					<Button id="B-TW-0004" variant="outline-primary" onClick={agregarOtraCuenta}>
						¿Quieres agregar una cuenta?
					</Button>
				</div>
			</div>
		</div >
	);
}
