import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import images from "../../assets/images";

const NotFoundPage = () => {
	return (
		<div className="container mt-2">
			<Card
				style={{
					textAlign: "center",
					padding: "30px",
					width: "760px",
					margin: "50px auto",
				}}
			>
				<img src={images.mantein} style={{ width: "300px", margin: "0 auto" }} alt="imagen" />
				<div id="info">
					<p>This page could not be found</p>
				</div>
				<Link to="/">
					<Button id="B-TW-0010">Volver al inicio</Button>
				</Link>
			</Card>
		</div>
	);
};

export default NotFoundPage;
