import { useState, useRef } from "react";
import { Form, Button } from "react-bootstrap";
import { getTipoCuenta, convertToMoney, getImage } from "../../utils/helper/utils";
import images from "../../assets/images";
import Mandato from "./Mandato";

//const texto = "Fondos de origen: Es el fondo en donde se invierten tus ahorros en tu AFP actual.";

export default function CuentasVoluntarias({ cuentas, propias, onCallback, onUpdateCallback }) {
    const [isChecked, setIsChecked] = useState(true);
    const [allIsChecked, setIsAllChecked] = useState(true);
    const [disabled, setDisabled] = useState([]);
    const checkboxesRef = useRef([]);

    const onChangeAll = (e) => {
        const checked = e.currentTarget.checked;

        setIsChecked(checked);
        setIsAllChecked(checked);

        checkboxesRef.current.forEach(checkbox => {
            checkbox.checked = checked;
            onUpdateCallback(null, checked, true);
        });

        if (checked) {
            setDisabled([]);
        } else {
            setDisabled(cuentas.map((_, index) => `B-TW-0006-${index + 1}`));
        }
    };

    const onChange = (e, item, name) => {
        const checked = e.currentTarget.checked;

        if (checkboxesRef.current.every(checkbox => !checkbox.checked)) {
            setIsChecked(false);
        }

        if (checkboxesRef.current.every(checkbox => checkbox.checked)) {
            setIsChecked(true);
        }

        onUpdateCallback(item, checked);
        if (checked) {
            setDisabled(disabled.filter(item => item !== name));
            setIsAllChecked(true);
        } else {
            setDisabled(x => [...x, name]);
        }
    };

    const handleShow = (index) => {
        if (onCallback) {
            onCallback(cuentas[index]);
        }
    };

    const onCallbackMandato = () => {

    }

    const isDisabled = (tipo) => {
        if (tipo !== "CCICO") {
            if (propias.length > 0) {
                const objeto = propias.find(item => item.Producto === tipo);
                if (tipo === objeto?.Producto) {
                    return true;
                }
            }
        }
        return false;
    };

    return cuentas.length > 0 ? (
        <div key="Voluntarias" className="container mt-3">
            <div className="row">
                <div className="col-10 offset-1 col-lg-6 offset-lg-3">
                    <div className="account-title">
                        <div className="d-flex">
                            <Form.Check
                                className="checks"
                                type="checkbox"
                                checked={isChecked}
                                onChange={onChangeAll} />
                            <span><strong>Cuentas Voluntarias</strong></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-10 offset-1 col-lg-6 offset-lg-3 account">
                    {cuentas.map((cuenta, index) => (
                        <div className="row account-separator" key={`voluntaria-${index}`}>
                            <div className="account-type">
                                <div className="row">
                                    <div className="col-12 col-lg-5">
                                        <div className="row">
                                            <div className="col-1">
                                                <Form.Check
                                                    type="checkbox"
                                                    defaultChecked={true}
                                                    ref={(el) => checkboxesRef.current[index] = el}
                                                    onChange={(e) => onChange(e, cuenta, `B-TW-0006-${index + 1}`)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-11 col-lg-9">
                                                <span className="account-code">{getTipoCuenta(cuenta.tipo.toString())}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <strong>AFP de origen:</strong> {cuenta.origen}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                Saldo total: ${convertToMoney(cuenta.saldo)}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-8 offset-4 col-lg-8 offset-lg-0">
                                                <div className="row">
                                                    {cuenta.fondos.map((item, indexf) => (
                                                        <div className={`col-${cuenta.fondos.length === 2 ? '3' : '6'} center-content`} key={indexf}>
                                                            <img
                                                                src={getImage(item.origen, false)}
                                                                width="39"
                                                                height="39"
                                                                alt={item.origen}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-1">
                                        <div className="center-content">
                                            <img src={images.arrowRight} alt="arrow" width={36} height={36} className="d-none d-lg-block" />
                                            <img src={images.arrowDown} alt="arrow" width={36} height={36} className="d-block d-sm-block d-md-block d-lg-none" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="account-green-border">
                                            <div className="row">
                                                <div className="col-12 col-lg-9">
                                                    <strong>AFP de destino:</strong> Modelo
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    {cuenta.Porcentaje !== undefined && cuenta.Porcentaje !== 0 ? (
                                                        <p>Traspaso {cuenta.Porcentaje == 100 ? "total" : "parcial"}: {cuenta.Porcentaje}%</p>
                                                    ) : (
                                                        <p>Traspaso total: 100%</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-lg-7">
                                                    <strong>Fondo de destino </strong>
                                                </div>
                                                <div className="col-4 offset-4 col-lg-5 offset-lg-0">
                                                    <div className="row center-content">
                                                        {cuenta.fondos.map((fondo, indexf) => {
                                                            if (propias.length > 0) {
                                                                const objeto = propias.find(item => item.Producto === cuenta.tipo);
                                                                if (cuenta.tipo === objeto?.Producto) {
                                                                    var arrayFondos = objeto?.Fondos;
                                                                    fondo.nombre = arrayFondos[indexf] !== undefined ? arrayFondos[indexf].Origen : arrayFondos[0].Origen;
                                                                }
                                                            }
                                                            return (
                                                                <div className="col-5 col-lg-6" key={`fondo-info-${index}-${indexf}`}>
                                                                    <img
                                                                        src={getImage(fondo.nombre, true)}
                                                                        width="39"
                                                                        height="39"
                                                                        alt={fondo.nombre}
                                                                    />
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            {!isDisabled(cuenta.tipo) && (
                                                allIsChecked && (
                                                    !disabled.find(item => item === `B-TW-0006-${index + 1}`) && (
                                                        <div className="row mt-3">
                                                            <div className="col-12 text-center">
                                                                <Button
                                                                    id={`B-TW-0006-${index + 1}`}
                                                                    variant="outline-primary"
                                                                    onClick={() => handleShow(index)}>
                                                                    Editar fondo de destino
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(cuenta.tipo === "APV" || cuenta.tipo === "CTA2") && (
                                <div className="account-types">
                                    <Mandato cuenta={cuenta} onCallback={onCallbackMandato} />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    ) : null;
}