import { NationalityC } from "../../types/NationalityC";
import { capitalizeFirstLetter } from "../../utils/helper/utils";

export const mapToNationality = (json: any): NationalityC => {    
    var code = json.codigo.replace(" ", "");
    return {
        Id: json.id,
        Name: capitalizeFirstLetter(json.nombre),
        Code: code,
        Image: `https://flagcdn.com/${code.toLocaleLowerCase()}.svg`
    };
};

export const mapToNationalities = (jsonArray: any[]): NationalityC[] => {
    return jsonArray.map(mapToNationality);
};