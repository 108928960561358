import "./Tooltip.scss";
import { useState, useRef } from "react";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import images from "../../assets/images";

export default function Tooltip({ text, image }) {
    const [show, setShow] = useState(false);
    const target = useRef(null);

    const toggleShow = () => setShow(prevShow => !prevShow);

    const handleMouseEnter = () => setShow(true);
    const handleMouseLeave = () => setShow(false);

    return (
        <>
            <img
                src={images.question}
                alt="question"
                ref={target}
                onClick={toggleShow}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{ cursor: "pointer" }}
            />
            <Overlay
                show={show}
                placement="top"
                containerPadding={20}
                target={target.current}
            >
                <Popover className="modelo-popover">
                    <Popover.Content className="tooltip-content">
                        {text && (<p>{text}</p>)}
                        {image && (
                            <img src={image} alt="cedula" width={300} height={100} />
                        )}
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    );
}