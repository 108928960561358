import { useEffect, useState } from "react";
import { SendRequest } from "../../services/RequestHandler";
import { Decrypt } from "../../utils/SessionCrypto/Crypto";
import { mapToSingle } from "../../components/Saldos/utils";
import { Saldos } from "../../types/Saldos";
import { Propias } from "../../types/Propias";
import response from "../../utils/markup/saldos.json";
import ConfigManager from "../../settings/ConfigManager";
import { getTipoCuenta } from "../../utils/helper/utils";

interface CuentasPropiasProps {
    onCallback: (m: any) => void;
    setRender: (render: boolean) => void;
}

const CuentasPropias: React.FC<CuentasPropiasProps> = ({ onCallback, setRender }) => {
    const [cuentas, setCuentas] = useState<Propias[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (ConfigManager.MARKUP) {
                    const response2: Saldos = mapToSingle(response);
                    setCuentas(response2.Response);
                    onCallback(response2.Response);
                } else {
                    const data = Decrypt("data");

                    const result = await SendRequest({
                        url: `${ConfigManager.API_BACK_URL}/api/saldos/${data.run}`,
                        method: "GET"
                    });
                    
                    if (result.data.length > 0) {
                        const response2: Saldos = mapToSingle(result);
                        setCuentas(response2.Response);
                        onCallback(response2.Response);
                    }
                }
            } finally {
                setRender(true);
            }
        };

        fetchData();
    }, []);

    return cuentas.length > 0 ? (
        <div key="Propias" className="container mt-3">
            <div className="row">
                <div className="col-10 offset-1 col-lg-6 offset-lg-3">
                    <div className="account-title">
                        <div className="d-flex">
                            <span><strong>Cuentas Modelo</strong></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-10 offset-1 col-lg-6 offset-lg-3 account">
                    {cuentas.map((item, index) => (
                        <>
                            <div className="row account-separator" key={`cuenta-propia-${index}`} id={`cuenta-propia-${index}`}>
                                <div className="col-8 account-type">
                                    <p className="account-code">{getTipoCuenta(item.Producto)}</p>
                                    <p className="text-small">{item.Producto}</p>
                                    <p className="text-small">{item.Descripcion}</p>
                                    <p><strong>AFP de origen:</strong> Modelo</p>
                                </div>
                                <div className="col-4 account-icon">
                                    <div className="row">
                                        {item.Fondos.map((fondo, i) => (
                                            <div className="col-6" key={`fondo-p-${index}-${i}`} id={`fondo-p-${index}-${i}`}>
                                                <img src={fondo.Imagen} width="39" height="39" alt={fondo.Origen} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </div>
    ) : null;
}

export default CuentasPropias;