import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { SendRequest } from "../../services/RequestHandler";
import FichaConfirmaEmpleador from "./FichaConfirmaEmpleador";
import ConfigManager from "../../settings/ConfigManager";

const AntecedentesLaborales = ({ datos }) => {
    const [type, setType] = useState("");
    const [exist, setExist] = useState(false);

    const formEmpleador = [1, 2, 4, 5, 6];

    useEffect(() => {
        const fetchData = async (Id) => {
            const result = await SendRequest({
                url: `${ConfigManager.API_BACK_URL}/api/tipo/${Id}`,
                method: "GET"
            });

            if (result.code === 0) {
                const habilitaFormEmpleador = formEmpleador.some(item => item === Id);

                setExist(habilitaFormEmpleador);
                setType(result.data.detalle);
            }
        }
        fetchData(datos.TipoEmpleado);
    }, []);

    return (
        <>
            <fieldset>
                <div className="row mb-3">
                    <Form.Label htmlFor="tipoempleador" className="col-sm-3 col-form-label">Tipo de Empleado</Form.Label>
                    <div className="col-sm-9">
                        <Form.Control type="text" className="form-control-plaintext text-uppercase" id="tipoempleador" value={type} readOnly />
                    </div>
                </div>
                <hr className="hr" />
            </fieldset>

            {exist && (datos.Empleadores.map((item, i) => <FichaConfirmaEmpleador key={i} data={item} />))}
        </>
    );
}

export default AntecedentesLaborales;
