import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ScrollToTop from "../../utils/ScrollToTop";
import HomePage from "../../pages/HomePage/HomePage";
import AuthenticationPage from "../../pages/AuthenticationPage/AuthenticationPage";
import FormValidate from "../../pages/AuthenticationPage/FormValidate";
import FormTVI from "../../pages/AuthenticationPage/FormTVI";
import FormClaveUnicaFAO from "../../pages/AuthenticationPage/FormClaveUnicaFAO";
import AccountPage from "../../pages/AccountPage/AccountPage";
import PersonalPage from "../../pages/PersonalPage/PersonalPage";
import ConfirmPage from "../../pages/ConfirmPage/ConfirmPage";
import VoucherPage from "../../pages/VoucherPage/VoucherPage";
import AffiliateVerificationPage from "../../pages/GlobalPage/AffiliateVerificationPage";
import NotFoundPage from "../../pages/GlobalPage/NotFoundPage";
import ErrorPage from "../../pages/GlobalPage/ErrorPage";
import { Decrypt } from "../../utils/SessionCrypto/Crypto";

class Main extends React.Component {
	render() {

		var name = "";
		var iniciales = "";

		try {
			var data = Decrypt("data");

			if (data?.isAuthenticated && data?.name !== null && data?.name !== undefined) {
				name = `${data.name} ${data.fatherName}`;
				iniciales = `${Array.from(data.name)[0]}${Array.from(data.fatherName)[0]}`;
			}
		} finally {

		}

		return (
			<div id="main-container">
				<BrowserRouter>
					<Header name={name} iniciales={iniciales} />
					<ScrollToTop />
					<main>
						<Switch>
							<Route path="/" exact render={(props) => <HomePage {...props} />} />
							<Route path="/autenticacion" exact render={(props) => <AuthenticationPage {...props} />} />
							<Route path="/validate" exact render={(props) => <FormValidate {...props} />} />
							<Route path="/tvi" exact render={(props) => <FormTVI {...props} />} />
							<Route path="/fao" exact render={(props) => <FormClaveUnicaFAO {...props} />} />
							<Route path="/cuentasyfondo" exact render={(props) => <AccountPage {...props} />} />
							<Route path="/misdatos" exact render={(props) => <PersonalPage {...props} />} />
							<Route path="/confirmartraspaso" exact render={(props) => <ConfirmPage {...props} />} />
							<Route path="/bienvenida" exact render={(props) => <VoucherPage {...props} />} />
							<Route path="/yaeresinfo" exact render={(props) => <AffiliateVerificationPage {...props} />} />
							<Route path="/error" exact render={(props) => <ErrorPage {...props} />} />
							<Route path="*" exact render={(props) => <NotFoundPage {...props} />} />
						</Switch>
					</main>
					<Footer />
				</BrowserRouter>
			</div>
		);
	}
}

export default Main;
