import { Form, Button } from "react-bootstrap";
import { useState } from "react";
import { getTipoCuenta, convertToMoney, getImage } from "../../utils/helper/utils";
import images from "../../assets/images";

export default function CuentasObligatorias({ cuentas, onCallback, onUpdateCallback }) {
    const [isChecked, setIsChecked] = useState(true);
    const texto = "Fondos de origen: Es el fondo en donde se invierten tus ahorros en tu AFP actual.";

    const onChangeAll = (e) => {
        const checked = e.currentTarget.checked;
        setIsChecked(checked);
        onUpdateCallback(null, checked);
    };

    const handleShow = (index) => {
        if (onCallback) {
            onCallback(cuentas[index]);
        }
    };

    return cuentas.length > 0 ? (
        <div key="Obligatorias" className="container mt-3">
            <div className="row">
                <div className="col-10 offset-1 col-lg-6 offset-lg-3">
                    <div className="account-title">
                        <div className="d-flex">
                            <Form.Check
                                type="checkbox"
                                checked={isChecked}
                                onChange={onChangeAll} />
                            <span><strong>Cuentas Obligatorias</strong></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-10 offset-1 col-lg-6 offset-lg-3 account">
                    {cuentas.map((cuenta, index) => (
                        <div className="row" key={`obligatoria-${index}`}>
                            <div className="account-type">
                                <div className="row">
                                    <div className="col-12 col-lg-5">
                                        <div className="row">
                                            <div className="col-11 col-lg-8">
                                                <span className="account-code">{getTipoCuenta(cuenta.tipo.toString())}</span>
                                            </div>
                                            <div className="col-1">

                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <strong>AFP de origen:</strong> {cuenta.origen}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                Saldo total: ${convertToMoney(cuenta.saldo)}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-8 offset-4 col-lg-8 offset-lg-0">
                                                <div className="row">
                                                    {cuenta.fondos.map((item, indexf) => (
                                                        <div className="col-3 center-content" key={indexf}>
                                                            <img
                                                                src={getImage(item.origen, false)}
                                                                width="39"
                                                                height="39"
                                                                alt={item.origen}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-1">
                                        <div className="center-content">
                                            <img src={images.arrowRight} alt="arrow" width={36} height={36} className="d-none d-lg-block" />
                                            <img src={images.arrowDown} alt="arrow" width={36} height={36} className="d-block d-sm-block d-md-block d-lg-none" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="account-green-border">
                                            <div className="row">
                                                <div className="col-12 col-lg-9">
                                                    <strong>AFP de destino:</strong> Modelo
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    {cuenta.Porcentaje !== undefined && cuenta.Porcentaje !== 0 ? (
                                                        <p>Traspaso {cuenta.Porcentaje == 100 ? "total" : "parcial"}: {cuenta.Porcentaje}%</p>
                                                    ) : (
                                                        <p>Traspaso total: 100%</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-lg-7">
                                                    <strong>Fondo de destino </strong>
                                                </div>
                                                <div className="col-4 offset-4 col-lg-5 offset-lg-0">
                                                    <div className="row center-content">
                                                        {cuenta.fondos.map((fondo, indexf) => (
                                                            <div className="col-5 col-lg-6" key={`fondo-info-${index}-${indexf}`}>
                                                                <img
                                                                    src={getImage(fondo.nombre, true)}
                                                                    width="39"
                                                                    height="39"
                                                                    alt={`${fondo.nombre}-color`}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>

                                            {isChecked && (
                                                <div className="row mt-3">
                                                    <div className="col-12 text-center">
                                                        <Button
                                                            id={`B-TW-0006-${index + 1}`}
                                                            variant="outline-primary"
                                                            onClick={() => handleShow(index)}>
                                                            Editar fondo de destino
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    ) : null;
}
