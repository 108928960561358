import "./AccountPage.scss";
import { Modal } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { SendRequest } from "../../services/RequestHandler";
import CuentasPropias from "./CuentasPropias";
import CuentasObligatorias from "./CuentasObligatorias";
import CuentasVoluntarias from "./CuentasVoluntarias";
import EditarFondos from "./EditarFondos";
import CabeceraPasos from "../../components/Header/Ribbon";
import Context from "../../context/context";
import Sabias from "./SabiasInfo";
import { Encrypt, Decrypt } from "../../utils/SessionCrypto/Crypto";
import Button from "../../components/Button/Button";
import ConfigManager from "../../settings/ConfigManager";
import Spinner from "react-bootstrap/Spinner";
import { mapToStates } from "../../components/State/utils";

export default function AccountPage() {
	const { state, actions } = useContext(Context);

	const [afiliado, setafiliado] = useState();
	const [cuentas, setcuentas] = useState();
	const [empleadores, setempleadores] = useState();

	const Obligatorias = ["CCICO", "CAI", "CCIAV"];
	const fondosDisponibles = ["A", "B", "C", "D", "E"];

	const [age, setAge] = useState(0);
	const [gender, setGender] = useState("");
	const [elegibles, setElegibles] = useState({ A: true, B: true, C: true, D: true, E: true });
	const [cuentaSeleccionada, SetCuentaSeleccionada] = useState();
	const [show, setShow] = useState(false);

	const [cuentasPropias, setCuentasPropias] = useState([]);
	const [arrayObligatorias, setArrayObligatorias] = useState([]);
	const [arrayVoluntarias, setArrayVoluntarias] = useState([]);

	const [redirect, setRedirect] = useState(false);
	const [disabled, setDisabled] = useState(false);

	const [isRendered, setIsRendered] = useState(false);

	useEffect(() => {
		const fetch = async () => {
			try {
				await setLoading(true);
				const data = Decrypt("dataPrevired");

				setafiliado(data.afiliado);
				setcuentas(data.cuentas);
				setempleadores(data.empleadores);
			} catch (error) {
				console.error("Error:", error);
			}
		}
		fetch();
	}, []);

	// Calcula la edad del afiliado
	useEffect(() => {
		const calcularEdad = () => {
			try {
				if (afiliado?.fechaNacimiento) {
					const fecha = afiliado.fechaNacimiento ? afiliado.fechaNacimiento : "19100926";
					const result = new Date(fecha.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3"));
					setAge(Math.abs(Math.round((new Date().getTime() - result.getTime()) / 1000 / (60 * 60 * 24) / 365.25)));
				}
			} catch (error) {
				console.error("Error:", error);
			}
		};

		calcularEdad();
	}, [afiliado?.fechaNacimiento]);

	// Obtiene el género
	useEffect(() => {
		const getGender = () => {
			try {
				if (afiliado?.genero) {
					setGender(afiliado.genero);
				}
			} catch (error) {
				console.error("Error:", error);
			}
		};
		getGender();
	}, [afiliado?.genero]);

	// Obtiene los fondos elegibles por edad y género
	useEffect(() => {
		const obtenerFondosElegibles = () => {
			try {
				if (gender && age !== 0) {
					const limits = { M: [56, 66, 99, 999, 999], F: [51, 61, 99, 999, 999] };
					const limarr = limits[gender];

					const updatedElegibles = { ...elegibles };

					for (const [key] of Object.entries(updatedElegibles)) {
						const inf = fondosDisponibles.indexOf(key);
						const lim = limarr[inf];
						updatedElegibles[key] = age < lim;
					}

					setElegibles(updatedElegibles);
				}
			} catch (error) {
				console.error("Error:", error);
			}
		};

		obtenerFondosElegibles();
	}, [age, gender]);

	// Obtiene las cuentas obligatorias y voluntarias
	useEffect(() => {
		const fetchCuentas = async () => {
			try {
				if (cuentas) {
					const cuentasObligatorias = [];
					const cuentasVoluntarias = [];

					cuentas.forEach(item => {
						if (Obligatorias.includes(item.tipo)) {
							cuentasObligatorias.push({ ...item, seleccionado: true, Porcentaje: 100 });
						} else {
							cuentasVoluntarias.push({ ...item, seleccionado: true, Porcentaje: 100 });
						}
					});

					cuentasObligatorias.sort((a, b) => Obligatorias.indexOf(a.tipo) - Obligatorias.indexOf(b.tipo));

					setArrayObligatorias(cuentasObligatorias);
					setArrayVoluntarias(cuentasVoluntarias);
				}
			} catch (error) {
				console.error("Error:", error);
			}
		};

		fetchCuentas();
	}, [cuentas]);

	// Carga inicial
	useEffect(() => {
		const obtenerFondosElegibles = () => {
			try {
				if (cuentas) {
					cuentas.map((item) => {
						item.tipoTraspaso = "total";
						item.Porcentaje = 100;
						item.fondos[0].fondoRecaudador = true;

						if (!elegibles.A) {
							item.fondos.map((item) => {
								if (item.nombre === "A") {
									item.nombre = "B";
								}
							});
						}

						if (!elegibles.B) {
							item.fondos.map((item) => {
								if (item.nombre === "B") {
									item.nombre = "C";
								}
							});
						}
					});
				}
			} catch (error) {
				console.error("Error:", error);
			}
		};

		obtenerFondosElegibles();
	}, [elegibles]);

	useEffect(() => {
		const getStates = async () => {
			try {
				setLoading(true);
				var result = await SendRequest({
					url: `${ConfigManager.API_BACK_URL}/api/region`,
					method: "GET"
				});

				const response = mapToStates(result.data);
				Encrypt("states", response);
			} catch (error) {
				console.error('Error fetching states:', error);
			} finally {
				setLoading(false);
			}
		};
		getStates();
	}, []);

	const handleCallback = (data) => {
		setShow(true);
		SetCuentaSeleccionada(data);
	};

	const handleCallbackVoluntarias = (data, checked) => {
		const updatedArrayVoluntarias = arrayVoluntarias.map((item) => {
			// Si data es null, actualiza seleccionado en todos los items
			if (data === null) {
				return { ...item, seleccionado: checked };
			}

			// Si item es igual a data, actualiza seleccionado
			if (item === data) {
				return { ...item, seleccionado: checked };
			}

			// De lo contrario, no cambies el item
			return item;
		});

		setArrayVoluntarias(updatedArrayVoluntarias);
	};

	const handleCallbackObligatorias = (data, checked) => {
		const updatedArrayObligatorias = arrayObligatorias.map((item) => {
			// Si data es null, actualiza seleccionado en todos los items
			if (data === null) {
				return { ...item, seleccionado: checked };
			}

			// Si item es igual a data, actualiza seleccionado
			if (item === data) {
				return { ...item, seleccionado: checked };
			}

			// De lo contrario, no cambies el item
			return item;
		});

		setArrayObligatorias(updatedArrayObligatorias);

		/*if (checked) {
			setArrayObligatorias(x => [...x, data]);

			if (clear) {
				setArrayObligatorias(arrayObligatorias);
			}
		} else {
			setArrayObligatorias(arrayObligatorias.filter(item => item !== data));

			if (clear) {
				setArrayObligatorias([]);
			}
		}*/
	};

	const handleCallbackClose = () => {
		setShow(false);
	};

	const handleCuentasPropiasCallback = (cuentas) => {
		setCuentasPropias(cuentas);
	};

	const getRegion = async (comuna) => {
		const result = await fetch(`${ConfigManager.API_BACK_URL}/api/Region/Comuna/${comuna}`, {
			method: "GET",
			headers: { accept: "application/json", "Content-Type": "application/json" }
		});

		const json = await result.json();

		if (json.code === 0) {
			return json.data;
		}

		return null;
	}

	const onClick = async () => {
		try {
			await setLoading(true);

			var result = await getRegion(afiliado.codigoUnicoTerritorial);

			const data = Decrypt("data");
			const data2 = Decrypt("dataPrevired");
			data2.cuentas = arrayVoluntarias.concat(arrayObligatorias);
			Encrypt("dataPrevired", data2);

			const nombreComuna = result?.comunas.find(x => x.cutComuna === afiliado.codigoUnicoTerritorial).nombre;

			var datos = {
				response: {
					Solicitud: data.solicitud,
					Token: data.token,
					Ticket: data2.ticket,
					Cuentas: arrayVoluntarias.concat(arrayObligatorias),
					Datos: {
						Prospecto: data.Prospecto,
						Nombre: afiliado.nombres,
						ApellidoPaterno: afiliado.apellidoPaterno,
						ApellidoMaterno: afiliado.apellidoMaterno,
						Rut: data.run,
						FechaNacimiento: afiliado.fechaNacimiento,
						Nacionalidad: "",
						NombreNacionalidad: "",
						Email: afiliado.email,
						Telefono: afiliado.telefonoCelular,
						Direccion: afiliado.direccion,
						Region: result?.id,
						NombreRegion: result?.nombre,
						Comuna: afiliado.codigoUnicoTerritorial,
						NombreComuna: nombreComuna,
						Cartola: false,
						Sexo: afiliado.genero,
						TipoEmpleado: afiliado.tipo,
						Empleadores: empleadores,
					}
				}
			};

			Encrypt("misdatos", datos);

			var dataLog = {
				Rut: data.run,
				Autenticacion: data.autenticacion,
				Device: data.device,
				Json: JSON.stringify({
					response: {
						solicitud: datos.response.Solicitud,
						token: datos.response.Token,
						ticketId: datos.response.Ticket,
						cuentasEditadas: datos.response.Cuentas,
						datosPersonales: datos.response.Datos
					}
				})
			};

			await SendRequest({
				url: `${ConfigManager.API_BACK_URL}/api/log/cuentas`,
				method: "POST",
				body: JSON.stringify(dataLog)
			});

			setRedirect(true);
		} catch (error) {
			console.error("Error:", error);
		} finally {
			await setLoading(false);
		}
	};

	useEffect(() => {
		const hayObligatoriasActivas = arrayObligatorias.some(x => x.seleccionado === true);
		const hayVoluntariasActivas = arrayVoluntarias.some(x => x.seleccionado === true);

		setDisabled(!(hayObligatoriasActivas || hayVoluntariasActivas));

	}, [arrayObligatorias, arrayVoluntarias]);

	const setLoading = async (isLoading) => {
		actions({ type: "setState", payload: { ...state, isLoading } });
	};

	const setRender = async (render) => {
		setIsRendered(render);
		await setLoading(false);
	};

	if (redirect) {
		return (<Redirect to={{ pathname: "/misdatos" }} />);
	}

	return (
		<>
			<CabeceraPasos paso="1" />
			<Modal show={show} onHide={() => setShow(false)} animation={false} backdrop="static">
				<Modal.Body>
					<EditarFondos cuenta={cuentaSeleccionada} elegibles={elegibles} onCallbackClose={handleCallbackClose} />
				</Modal.Body>
			</Modal>

			<CuentasPropias onCallback={handleCuentasPropiasCallback} setRender={setRender} />

			{isRendered ? (
				<>
					<CuentasObligatorias cuentas={arrayObligatorias} onCallback={handleCallback} onUpdateCallback={handleCallbackObligatorias} />
					<CuentasVoluntarias cuentas={arrayVoluntarias} propias={cuentasPropias} onCallback={handleCallback} onUpdateCallback={handleCallbackVoluntarias} />
				</>
			) : (
				<div className="d-flex justify-content-center">
					<Spinner animation="grow" role="status" variant="info" className="mt-3">
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				</div>
			)}

			<div className="offset-sm-2 col-sm-8 offset-md-3 col-md-6 offset-lg-3 col-lg-6 mt-3">
				<div className="text-center">
					{isRendered && (
						<Button
							id="B-TW-0007"
							variant="primary"
							onClick={onClick}
							loading={state.loading}
							disabled={disabled}>
							Continuar
						</Button>
					)}
				</div>
			</div>
			<div className="col-10 offset-1 col-lg-6 offset-lg-3">
				<Sabias />
			</div>
			<div className="col-sm-12 col-md-12 offset-lg-3 col-lg-6">
				{ConfigManager.SHOW_MARKUP && (
					<div className="alert alert-danger mt-3" role="alert">
						<pre>
							{JSON.stringify(arrayVoluntarias.concat(arrayObligatorias), null, "\t")}
						</pre>
					</div>)
				}
			</div>
		</>
	);
}