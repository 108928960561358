import "./ConfirmPage.scss";
import { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { Decrypt } from "../../utils/SessionCrypto/Crypto";
import Spinner from "react-bootstrap/Spinner";
import AccordionConfirmar from "./AccordionConfirmar";
import StepHeader from "../../components/Header/Ribbon";
import Disclaimer from "./Disclaimer";
import Context from "../../context/context";
import Swal from "sweetalert2";
import Button from "../../components/Button/Button";
import { mapToPersona, mapToCuentas } from "./utils";
import ConfigManager from "../../settings/ConfigManager";

export default function ConfirmarTraspaso() {
	const { state, actions } = useContext(Context);
	const [dataFile, setDataFile] = useState(null);
	const [redirect, setRedirect] = useState(false);
	const [misDatos, setMisDatos] = useState([]);
	const [paso, setPaso] = useState("");

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);

	const [pasosCompletados, setPasosCompletados] = useState({
		confirmar: false,
		guardar: false,
		notificar: false,
		resultado: false,
		ingresar: false,
	});

	useEffect(() => {
		const misdatos = Decrypt("misdatos");
		setMisDatos(misdatos);
	}, []);

	const setLoading = async (isLoading) => {
		actions({ type: "setState", payload: { ...state, isLoading } });
	};

	const confirmar = async (Request) => {
		try {
			if (!pasosCompletados.confirmar) {
				setPaso("Confirmando");
				const url = `${ConfigManager.API_BACK_URL}/api/notificar/confirmar`;

				const response = await fetch(url, {
					method: "POST",
					headers: ConfigManager.JSON_HEADER,
					body: JSON.stringify(Request)
				});

				const json = await response.json();

				if (json.code === 0) {
					setPasosCompletados(prevState => ({
						...prevState,
						confirmar: true,
					}));

					await guardar(Request);
				} else {
					setShow(false);
					await setLoading(false);

					Swal.fire({
						icon: "error",
						text: json.message,
						confirmButtonText: "Ok",
						allowOutsideClick: false,
					});
				}
			} else {
				await guardar(Request);
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				text: error,
				confirmButtonText: "Ok",
				allowOutsideClick: false,
			});
		}
	};

	const guardar = async (Request) => {
		try {
			if (!pasosCompletados.guardar) {
				setPaso("Guardando");
				const url = `${ConfigManager.API_BACK_URL}/api/notificar/guardar`;

				const response = await fetch(url, {
					method: "POST",
					headers: ConfigManager.JSON_HEADER,
					body: JSON.stringify(Request)
				});

				const json = await response.json();

				if (json.code === 0) {

					setPasosCompletados(prevState => ({
						...prevState,
						guardar: true,
					}));

					await notificar(Request);
				} else {
					setShow(false);
					await setLoading(false);

					Swal.fire({
						icon: "error",
						text: json.message,
						confirmButtonText: "Ok",
						allowOutsideClick: false,
					});
				}
			} else {
				await notificar(Request);
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				text: error,
				confirmButtonText: "Ok",
				allowOutsideClick: false,
			});
		}
	};

	const notificar = async (Request) => {
		try {
			if (!pasosCompletados.notificar) {
				setPaso("Notificando");
				const url = `${ConfigManager.API_BACK_URL}/api/notificar/notificar`;

				const response = await fetch(url, {
					method: "POST",
					headers: ConfigManager.JSON_HEADER,
					body: JSON.stringify(Request)
				});

				const json = await response.json();

				if (json.code === 0) {

					setPasosCompletados(prevState => ({
						...prevState,
						notificar: true,
					}));

					await resultado(Request);
				} else {
					setShow(false);
					await setLoading(false);

					Swal.fire({
						icon: "error",
						text: json.message,
						confirmButtonText: "Ok",
						allowOutsideClick: false,
					});
				}
			} else {
				await resultado(Request);
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				text: error,
				confirmButtonText: "Ok",
				allowOutsideClick: false,
			});
		}
	};

	const resultado = async (Request) => {
		try {
			if (!pasosCompletados.resultado) {
				setPaso("Obteniendo resultado");
				const url = `${ConfigManager.API_BACK_URL}/api/notificar/resultado`;

				const response = await fetch(url, {
					method: "POST",
					headers: ConfigManager.JSON_HEADER,
					body: JSON.stringify(Request)
				});

				const json = await response.json();

				if (json.code === 0) {

					setPasosCompletados(prevState => ({
						...prevState,
						resultado: true,
					}));

					await ingresar(Request);
				} else {
					setShow(false);
					await setLoading(false);

					Swal.fire({
						icon: "error",
						text: json.message,
						confirmButtonText: "Ok",
						allowOutsideClick: false,
					});
				}
			} else {
				await ingresar(Request);
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				text: error,
				confirmButtonText: "Ok",
				allowOutsideClick: false,
			});
		}
	};

	const ingresar = async (Request) => {
		try {
			if (!pasosCompletados.ingresar) {
				setPaso("Ingresando");
				const url = `${ConfigManager.API_BACK_URL}/api/notificar/ingresar`;

				const response = await fetch(url, {
					method: "POST",
					headers: ConfigManager.JSON_HEADER,
					body: JSON.stringify(Request)
				});

				const json = await response.json();

				if (json.code === 0) {

					setPasosCompletados(prevState => ({
						...prevState,
						ingresar: true,
					}));

					setDataFile(json.data);
					setRedirect(true);
				} else {
					setShow(false);
					await setLoading(false);

					Swal.fire({
						icon: "error",
						text: json.message,
						confirmButtonText: "Ok",
						allowOutsideClick: false,
					});
				}
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				text: error,
				confirmButtonText: "Ok",
				allowOutsideClick: false,
			});
		}
	};

	const onClick = async () => {
		try {
			setShow(true);
			await setLoading(true);

			var datos = misDatos.response.Datos;

			if (datos.TipoEmpleado === 6 && datos.Empleadores.length === 0) {
				datos.Empleadores = [{
					id: 1,
					razonSocialempleador: datos.Nombre + " " + datos.ApellidoPaterno + " " + datos.ApellidoMaterno,
					rutempleador: datos.Rut,
					emailNotifiacionempleador: datos.Email,
					telefonoempleador: datos.Telefono,
					direccionempleador: datos.Direccion,
					regionempleador: datos.Region,
					nombreRegion: datos.NombreRegion,
					comunaempleador: datos.NombreComuna,
					nombreComuna: datos.Comuna
				}];
			}

			const dataAuth = Decrypt("data");
			var Request = {
				Solicitud: misDatos.response.Solicitud,
				Ticket: misDatos.response.Ticket,
				Token: misDatos.response.Token,
				Cuentas: mapToCuentas(misDatos.response.Cuentas.filter(cuenta => cuenta.seleccionado)),
				DatosPersona: mapToPersona(datos, dataAuth.autenticacion, dataAuth.device)
			};
						
			await confirmar(Request);
		} finally {
			setShow(false);
			await setLoading(false);
		}
	};

	if (redirect) {
		return <Redirect to={{ pathname: "/bienvenida", state: { data: dataFile } }} />;
	}

	return (
		<>
			<StepHeader paso={3} />
			<div className="col-10 offset-1 col-lg-6 offset-lg-3">
				{misDatos.response && <AccordionConfirmar datos={misDatos.response} />}
			</div>
			<div className="col-10 offset-1 col-lg-6 offset-lg-3">
				<Disclaimer />
			</div>
			<div className="mt-3 mb-3">
				<div className="col text-center">
					<Modal show={show} onHide={handleClose} className="custom-modal-dialog">
						<Modal.Body className="custom-modal-content">
							<div className="text-center mb-3">
								<h4>{paso}</h4>
							</div>
							<div className="text-center">
								<Spinner animation="border" role="status" variant="success">
									<span className="visually-hidden">Loading...</span>
								</Spinner>
							</div>
						</Modal.Body>
					</Modal>
					<Button
						id="B-TW-0009"
						variant="primary"
						onClick={onClick}
						loading={state.loading}
					>
						Confirmar Traspaso
					</Button>
				</div>
				<div className="mt-3">
					<div className="col text-center">
						<Link to={{ pathname: "/misdatos" }}>
							<div className="link">Volver</div>
						</Link>
					</div>
				</div>
			</div>
		</>
	);
}