import React, { useState } from "react";
import { Form } from "react-bootstrap";
import validator from "validator";

interface EmailProps {
    validateForm: { email: boolean };
    setValidateForm: (validateForm: { email: boolean }) => void;
}

interface ErrorState {
    isValid: boolean;
    message: string;
}

const Email: React.FC<EmailProps> = (props) => {
    const [email, setEmail] = useState<string>("");
    const [error, setError] = useState<ErrorState>({ isValid: false, message: "" });

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        const value = event.target.value;
        const isEmpty = validator.isEmpty(value);
        const isValid = !isEmpty && validator.isEmail(value);

        let message = "";
        let isValidState = true;

        if (isEmpty) {
            message = "Por favor, ingrese una dirección de correo electrónico.";
            isValidState = false;
        } else if (!isValid) {
            message = "La dirección de correo electrónico tiene un formato incorrecto.";
            isValidState = false;
        } else {
            message = "La dirección de correo electrónico es correcta.";
        }

        setError({ isValid: isValidState, message: message });
        props.setValidateForm({ ...props.validateForm, email: isValid });
        setEmail(value);
    };

    return (
        <>
            <Form.Control
                name="email"
                type="text"
                placeholder="Correo electrónico"
                value={email}
                isInvalid={!error.isValid && error.message !== ""}
                isValid={error.isValid}
                onChange={onChange}
            />
            <Form.Control.Feedback type={error.isValid ? "valid" : "invalid"}>
                {error.message}
            </Form.Control.Feedback>
        </>
    );
};

export default Email;
