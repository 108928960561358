import { Redirect as BrowserRouter } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import Context from "../../context/context";
import { Encrypt, Decrypt } from "../../utils/SessionCrypto/Crypto";
import { SendRequest } from "../../services/RequestHandler";
import ConfigManager from "../../settings/ConfigManager";
import { Spinner } from "react-bootstrap";

export default function FormValidate() {
	const { state, actions } = useContext(Context);
	const [redirect, setRedirect] = useState(false);

	useEffect(() => {
		const query = new URLSearchParams(window.location.search);
		const token = query.get("token");

		if (token === null || token === "") {
			window.location.replace("/");
		}

		const ValidateToken = async (data, token) => {
			try {
				await setLoading(true);
				
				var result = await SendRequest({
					url: `${ConfigManager.API_BACK_URL}/api/ecert/status/cu`,
					method: "POST",
					body: JSON.stringify({
						Run: data.run,
						Token: data.token,
						Data: {
							Identificador: data.identificador,
							TokenRespuestaCU: token,
						},
						Device: data.device
					}),
				});

				if (result.code === 0 && result.data.Exito) {
					data.ObjetoGenerico = result.data.ObjetoGenerico;

					Encrypt("data", data);
					setRedirect(true);
				} else {
					ErrorMessage(result.data.Descripcion);
				}
			} finally {
				await setLoading(false);
			}
		};

		ValidateToken(Decrypt("data"), token);
	}, []);

	const ErrorMessage = (text) => {
		Swal.fire({
			icon: "error",
			html: text,
			showDenyButton: false,
			showCancelButton: false,
			confirmButtonText: "Ok",
			denyButtonText: `Don't save`
		}).then((result) => {
			if (result.isConfirmed) {
				window.location.replace("/");
			}
		});
	}

	const setLoading = async (isLoading) => {
		actions({ type: "setState", payload: { ...state, isLoading } });
	};

	return (
		<>
			{redirect ? (
				<BrowserRouter to={{ pathname: "/tvi", state: { json: "result" } }} />
			) : null}
			<div className="d-flex justify-content-center mt-5" style={{ height: "250px" }}>
				<Spinner animation="grow" role="status" variant="info" className="mt-3">
					<span className="visually-hidden">Loading...</span>
				</Spinner>
			</div>
		</>
	);
}