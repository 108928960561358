import { getImage } from "../../utils/helper/utils";

export default function origen({ esVisible, fondo }) {
    return esVisible ? (
        <div className="row">
            <div className="offset-lg-7 col-lg-5" style={{ textAlign: "right" }}>
                Fondo origen: <img src={getImage(fondo, true)} width="20" height="20" alt={fondo} />
            </div>
        </div>
    ) : null;
}