import Swal from "sweetalert2";

const headers = {
    accept: "application/json",
    "Content-Type": "application/json"
};

interface Props {
    url: string;
    method: string;
    body?: any;
}

export const SendRequest = async ({ url, method, body }: Props) => {
    try {
        
        const response = await fetch(url, {
            method: method,
            headers: headers,
            body: body
        });

        const json = await response.json();

        if (!response.ok) {
            Swal.fire({
                icon: "error",
                text: json.message,
                confirmButtonText: "Ok",
                allowOutsideClick: false,
            });
        }

        return json;
    } catch (error: any) {
        Swal.fire({
            icon: "error",
            text: error,
            confirmButtonText: "Ok",
            allowOutsideClick: false,
        });
    } finally {

    }
}