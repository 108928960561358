import React, { useState } from "react";
import { Form } from "react-bootstrap";
import validator from "validator";

interface PhoneProps {
    validateForm: { phone: boolean };
    setValidateForm: (validateForm: { phone: boolean }) => void;
}

interface ErrorState {
    isValid: boolean;
    message: string;
}

const Phone: React.FC<PhoneProps> = (props) => {
    const [phone, setPhone] = useState<string>("");
    const [error, setError] = useState<ErrorState>({ isValid: false, message: "" });

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        const value = event.target.value;
        let errorMessage = "";
        let isValid = true;

        if (validator.isEmpty(value)) {
            errorMessage = "Por favor, ingrese un número de celular.";
            isValid = false;
        } else if (value.length < 9) {
            errorMessage = "Por favor, ingrese un número de celular válido de 9 dígitos.";
            isValid = false;
        } else if (!validator.isNumeric(value, { no_symbols: true })) {
            errorMessage = "Por favor, ingrese un formato correcto para el número de celular.";
            isValid = false;
        }

        setError({ isValid, message: isValid ? "El número de celular es válido." : errorMessage });
        props.setValidateForm({ ...props.validateForm, phone: isValid });
        setPhone(value);
    };

    return (
        <>
            <div className="input-group">
                <span className="input-group-text" id="basic-addon1">+56</span>
                <Form.Control
                    name="phone"
                    type="text"
                    value={phone}
                    maxLength={9}
                    isInvalid={!error.isValid && error.message !== ""}
                    isValid={error.isValid}
                    placeholder="987526534"
                    onChange={onChange}
                />
                <Form.Control.Feedback type={error.isValid ? "valid" : "invalid"}>
                    {error.message}
                </Form.Control.Feedback>
            </div>
        </>
    );
};

export default Phone;