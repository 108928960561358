import { Municipality } from "../../types/Municipality";
import { State } from "../../types/State";

export const mapToState = (json: any): State => {
    return {
        Id: json.id,
        Name: json.nombre,
        municipalities: mapToMunicipalities(json.comunas)
    };
};

const mapToMunicipalities = (municipalities: any[]): Municipality[] => {
    return municipalities.map(municipality => ({
        Id: municipality.id,
        Name: municipality.nombre,
        Code: municipality.cutComuna
    }));
};

export const mapToStates = (jsonArray: any[]): State[] => {
    return jsonArray.map(mapToState);
};