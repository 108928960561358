import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { SendRequest } from "../../services/RequestHandler";
import { StateListData } from "../../components/State";
import Button from "../../components/Button/Button";
import ConfigManager from "../../settings/ConfigManager";
import validator from "validator";
import images from "../../assets/images";
import Inputmask from "inputmask";
import { formatIdentifier, isValidIdentifier } from "../../utils/helper/utils";
import Swal from "sweetalert2";
import { cleanInput } from "../../utils/helper/utils";

export default function EmploymentForm({ empleadores, datos, setDatos, setEmpleadores, setLoading }) {
    const [affiliateTypes, setAffiliateTypes] = useState([]);
    const [affiliateType, setAffiliateType] = useState(datos.TipoEmpleado);

    const formEmpleador = [1, 2, 4, 5, 6];
    const [employerExists, setEmployerExists] = useState(true);
    const [formEmpleadorVisible, setFormEmpleadorVisible] = useState(false);

    const [employer, setEmployer] = useState();
    const [selected, setSelected] = useState();
    const [disabled, setDisabled] = useState(true);
    const [message, setMessage] = useState([]);


    const [accion, setAccion] = useState("");

    useEffect(() => {
        const getAffiliateTypes = async () => {
            try {
                await setLoading(true);

                var result = await SendRequest({
                    url: `${ConfigManager.API_BACK_URL}/api/tipo`,
                    method: "GET"
                });

                if (result.code === 0) {
                    setAffiliateTypes(result.data);
                }
            } catch (error) {
                console.error('Error fetching affiliateTypes:', error);
            } finally {
                await setLoading(false);
            }
        };
        getAffiliateTypes();
    }, []);

    useEffect(() => {
        const id = parseInt(datos.TipoEmpleado);

        getAffiliateTypes(id);

    }, [datos.TipoEmpleado]);

    const onChangeAffiliateTypes = async (e) => {
        const Id = parseInt(e.target.value);
        getAffiliateTypes(Id);
    };

    const getAffiliateTypes = async (Id) => {
        try {
            setAffiliateType(Id);
            setDatos({ ...datos, TipoEmpleado: Id })

            const habilitaFormEmpleador = formEmpleador.some(item => item === Id);
            setEmployerExists(habilitaFormEmpleador);
            setFormEmpleadorVisible(!habilitaFormEmpleador);
            setSelected();
        } catch (error) {
            console.error('Error while changing affiliateTypes:', error);
        }
    };

    const newEmployer = () => {
        setEmployer({
            id: empleadores.length + 1,
            razonSocialempleador: "",
            rutempleador: "",
            emailNotifiacionempleador: "",
            telefonoempleador: "",
            direccionempleador: "",
            regionempleador: "0",
            nombreRegion: "",
            comunaempleador: "0",
            nombreComuna: ""
        });
    };

    const phoneIsInvalid = (telefono) => {
        const re = /^\d{9}$/;
        return !re.test(telefono);
    };

    const handleKeyDown = (event) => {
        if (event.ctrlKey && event.key === 'v') {
            event.preventDefault();
        }
        if (event.metaKey && event.key === 'v') {
            event.preventDefault();
        }
    };

    const inputMask = () => {
        const inputElement = document.getElementById("identifier");

        if (inputElement) {
            Inputmask({
                mask: "99.999.999-K",
                autoUnmask: true,
                showMaskOnFocus: true,
                showMaskOnHover: true,
                definitions: {
                    K: {
                        validator: "[0-9Kk]",
                        casing: "upper",
                    },
                },
            }).mask(inputElement);
        }
    };

    useEffect(() => {
        const validateData = () => {
            try {
                var isValidEmpleadores = true;

                if (datos.TipoEmpleado !== 3 && datos.TipoEmpleado !== 7) {
                    isValidEmpleadores = Boolean(employer?.razonSocialempleador) &&
                        Boolean(isValidIdentifier(employer?.rutempleador)) &&
                        (employer?.emailNotifiacionempleador ? validator.isEmail(employer?.emailNotifiacionempleador) : false) &&
                        Boolean(employer?.telefonoempleador?.length === 9) &&
                        Boolean(employer?.direccionempleador) &&
                        Boolean(employer?.comunaempleador) &&
                        Boolean(employer?.nombreComuna)
                }
                setDisabled(!isValidEmpleadores)
            } catch (message) {
                console.error(message);
            } finally {

            }
        };
        validateData();
    }, [employer]);

    useEffect(() => {
        var errores = [];
        empleadores.map((item) => {
            if (!item?.razonSocialempleador) {
                errores.push({ error: "Ingrese una razón social válida para el empleador." });
            }

            if (!isValidIdentifier(item?.rutempleador)) {
                errores.push({ error: "Ingrese un RUT válido para el empleador." });
            }

            if (item?.emailNotifiacionempleador && !validator.isEmail(item?.emailNotifiacionempleador)) {
                errores.push({ error: "Ingrese una dirección de correo electrónico válida para el empleador." });
            }

            if (item?.telefonoempleador?.length !== 9) {
                errores.push({ error: "Ingrese el número de teléfono del empleador." })
            }

            if (!item?.direccionempleador) {
                errores.push({ error: "Ingrese una dirección válida para el empleador." });
            }

            if (!item?.comunaempleador) {
                errores.push({ error: "Seleccione una comuna para el empleador." });
            }
        });

        setMessage(errores);
    }, [empleadores]);

    const Editar = (item) => {
        setFormEmpleadorVisible(true);
        setEmployer(item);
        setSelected(item.id);
        setAccion("EDITAR");
    };

    const Eliminar = (item) => {
        const values = [...empleadores];
        values.splice(values.findIndex((value) => value.id === item.id), 1);
        setEmpleadores(values);
    };

    return (
        <div className="container-data">
            <div className="container-data-type">
                <fieldset>
                    <legend>Antecedentes laborales</legend>
                    <div className="row mb-3">
                        <Form.Label htmlFor="affiliateTypes" className="col-sm-3 col-form-label">Tipo de afiliado</Form.Label>
                        <div className="col-sm-9">
                            <Form.Control
                                name="affiliateTypes"
                                as="select"
                                value={affiliateType}
                                isInvalid={affiliateType === 0}
                                isValid={affiliateType !== 0}
                                onChange={onChangeAffiliateTypes}
                            >
                                <option key="0" value="0">Seleccione un tipo de afiliado</option>
                                {affiliateTypes.map((item) => (
                                    <option key={item.id} value={item.previred}>{item.detalle}</option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Por favor, seleccione un tipo de afiliado
                            </Form.Control.Feedback>
                        </div>
                    </div>
                </fieldset>

                {employerExists && (
                    <>
                        <hr className="hr" />
                        {empleadores.length < 4 && (
                            <div className="row mb-3">
                                <div className="col-12 col-lg-4 mb-3">
                                    <Button
                                        variant="outline-primary"
                                        onClick={() => {
                                            newEmployer();
                                            setFormEmpleadorVisible(true);
                                            setAccion("CREAR");
                                        }}
                                    >
                                        + Nuevo empleador
                                    </Button>
                                </div>
                                <div className="col-12 col-lg-8">
                                    <div className="alert alert-primary d-flex align-items-center" role="alert">
                                        <img src={images.alert} alt="alert" className="bi flex-shrink-0 me-2" height={16} width={14} />
                                        <div>
                                            Si este no es tu empleador actual debes eliminarlo e ingresar uno nuevo.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {formEmpleadorVisible && (
                            <fieldset>
                                <legend>Datos empleador</legend>
                                <div className="row mb-3">
                                    <Form.Label htmlFor="rutempleador" className="col-sm-3 col-form-label">Rut</Form.Label>
                                    <div className="col-sm-9">
                                        <Form.Control
                                            id="identifier"
                                            name="identifier"
                                            type="text"
                                            placeholder="99.999.999-K"
                                            autoComplete="off"
                                            value={employer.rutempleador}
                                            isInvalid={validator.isEmpty(employer.rutempleador) || !isValidIdentifier(employer.rutempleador)}
                                            isValid={!validator.isEmpty(employer.rutempleador) && isValidIdentifier(employer.rutempleador)}
                                            maxLength={12}
                                            onKeyDown={handleKeyDown}
                                            onFocus={inputMask}
                                            disabled={accion === "EDITAR"}
                                            onChange={(e) => {
                                                var value = e.target.value;
                                                setEmployer({ ...employer, rutempleador: value });
                                            }}
                                        />
                                        <Form.Control.Feedback type={"invalid"}>
                                            {validator.isEmpty(employer.rutempleador)
                                                ? "Por favor, ingrese el RUT."
                                                : !isValidIdentifier(employer.rutempleador)
                                                    ? "El RUT ingresado es incorrecto."
                                                    : ""}
                                        </Form.Control.Feedback>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <Form.Label htmlFor="razonSocial" className="col-sm-3 col-form-label">Razón social</Form.Label>
                                    <div className="col-sm-9">
                                        <Form.Control
                                            name="razonSocial"
                                            type="text"
                                            placeholder="Razon social"
                                            value={employer.razonSocialempleador}
                                            isInvalid={employer.razonSocialempleador === ""}
                                            isValid={employer.razonSocialempleador !== ""}
                                            onChange={(e) => {
                                                const re = /^[-'A-Za-zÀ-ÖØ-öø-ÿ0-9\s]+$/g;
                                                const value = e.target.value;
                                                if (value === "" || re.test(value)) {
                                                    setEmployer({ ...employer, razonSocialempleador: value });
                                                }
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Por favor, ingrese una razón social válida.
                                        </Form.Control.Feedback>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <Form.Label htmlFor="emailNotifiacionempleador" className="col-sm-3 col-form-label">Correo electrónico</Form.Label>
                                    <div className="col-sm-9">
                                        <Form.Control
                                            type="text"
                                            name="emailNotifiacionempleador"
                                            placeholder="Correo electrónico a notificar"
                                            value={employer.emailNotifiacionempleador}
                                            isInvalid={employer?.emailNotifiacionempleador === "" || !validator.isEmail(employer?.emailNotifiacionempleador)}
                                            isValid={employer?.emailNotifiacionempleador !== "" && validator.isEmail(employer?.emailNotifiacionempleador)}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setEmployer({ ...employer, emailNotifiacionempleador: value });
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Por favor, ingrese una dirección de correo electrónico válida.
                                        </Form.Control.Feedback>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <Form.Label htmlFor="telefonoempleador" className="col-sm-3 col-form-label">Teléfono</Form.Label>
                                    <div className="col-sm-9">
                                        <div className="input-group">
                                            <span className="input-group-text">+56</span>
                                            <Form.Control
                                                type="text"
                                                name="telefonoempleador"
                                                placeholder="Teléfono"
                                                maxLength="9"
                                                value={employer.telefonoempleador}
                                                isInvalid={phoneIsInvalid(employer.telefonoempleador)}
                                                isValid={!phoneIsInvalid(employer.telefonoempleador)}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^[0-9\b]*$/.test(value)) {
                                                        setEmployer({ ...employer, telefonoempleador: value });
                                                    }
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {employer?.telefonoempleador?.length === 0
                                                    ? "Por favor, ingrese un número de teléfono."
                                                    : "Por favor, ingrese un número de teléfono válido (9 dígitos)."}
                                            </Form.Control.Feedback>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <Form.Label htmlFor="direccionempleador" className="col-sm-3 col-form-label">Dirección</Form.Label>
                                    <div className="col-sm-9">
                                        <Form.Control
                                            type="text"
                                            name="direccionempleador"
                                            placeholder="Dirección"
                                            value={cleanInput(employer.direccionempleador)}
                                            isInvalid={employer.direccionempleador === ""}
                                            isValid={employer.direccionempleador !== ""}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^[\p{L}\p{M}\p{N}\s'-]*$/u.test(value)) {
                                                    setEmployer({ ...employer, direccionempleador: cleanInput(value) });
                                                }
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Por favor, ingrese una dirección válida.
                                        </Form.Control.Feedback>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <StateListData
                                        index={0}
                                        defaultState={employer.regionempleador}
                                        onStateChange={(data) => setEmployer({
                                            ...employer,
                                            comunaempleador: "",
                                            nombreComuna: "",
                                            regionempleador: data?.Id || 0,
                                            nombreRegion: data?.Name || ""
                                        })}
                                        defaultMunicipality={employer.comunaempleador}
                                        onMunicipalityChange={(data) => setEmployer({
                                            ...employer,
                                            comunaempleador: data?.Code || "",
                                            nombreComuna: data?.Name || ""
                                        })}
                                        setLoading={setLoading}
                                    />
                                </div>
                                <div className="row mb-3">
                                    <div className="col-6 col-lg-2 offset-lg-8">
                                        <Button
                                            variant="outline-primary"
                                            disabled={disabled}
                                            onClick={() => {
                                                const values = [...empleadores];

                                                if (accion === "CREAR") {
                                                    const exists = empleadores.find(item => item.rutempleador === employer.rutempleador);
                                                    if (exists) {
                                                        Swal.fire({
                                                            icon: "warning",
                                                            text: "El empleador ingresado ya existe.",
                                                            confirmButtonText: "Ok",
                                                            allowOutsideClick: false,
                                                        });
                                                    } else {
                                                        values.push(employer);
                                                        setEmpleadores(values);
                                                        setFormEmpleadorVisible(false);
                                                    }
                                                } else if (accion === "EDITAR") {
                                                    const exists = empleadores.find(item => item.rutempleador === employer.rutempleador);
                                                    var index = empleadores.find(item => item.id === employer.id);

                                                    if (selected !== exists.id) {
                                                        Swal.fire({
                                                            icon: "warning",
                                                            text: "El empleador ingresado ya existe.",
                                                            confirmButtonText: "Ok",
                                                            allowOutsideClick: false,
                                                        });
                                                    } else {
                                                        var index = empleadores.findIndex(item => item.id === employer.id);

                                                        values[index] = employer;
                                                        setEmpleadores(values);
                                                        setFormEmpleadorVisible(false);
                                                        setSelected();
                                                    }
                                                }
                                            }}
                                        >
                                            Guardar
                                        </Button>
                                    </div>
                                    <div className="col-6 col-lg-2">
                                        <Button
                                            variant="outline-primary"
                                            onClick={() => {
                                                setFormEmpleadorVisible(false);
                                                setSelected();
                                                setAccion("");
                                            }}
                                        >
                                            Cancelar
                                        </Button>
                                    </div>
                                </div>
                                <hr className="hr" />
                            </fieldset>
                        )}
                        <div className="d-none d-lg-block">
                            <div className="table-responsive" style={{ border: "1px solid rgba(0,0,0,.075)", borderRadius: "5px" }}>
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col" className="d-none">#</th>
                                            <th scope="col" className="d-none d-md-table-cell">Rut</th>
                                            <th scope="col" className="d-none d-md-table-cell">Razón social</th>
                                            <th scope="col" className="d-none d-md-table-cell">Correo</th>
                                            <th scope="col" className="d-none d-md-table-cell">Teléfono</th>
                                            <th scope="col" className="d-none d-md-table-cell">Dirección</th>
                                            <th scope="col" className="d-none d-md-table-cell">Región</th>
                                            <th scope="col" className="d-none d-md-table-cell">Comuna</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {empleadores && empleadores.length > 0 ? (
                                            empleadores.map((item, index) => (
                                                <tr key={index} className={selected === item.id ? "table-primary" : ""}>
                                                    <td style={{ maxWidth: '45px' }}>
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <a className="pointer" onClick={() => Editar(item)}>
                                                                    <img src={images.edit} alt="Edit record" width="15" height="18" />
                                                                </a>
                                                            </div>
                                                            <div className="col-3">
                                                                <a className="pointer" onClick={() => Eliminar(item)}>
                                                                    <img src={images.erase} alt="Delete record" width="15" height="18" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <th scope="row" className="d-none">{item.id}</th>
                                                    <td>
                                                        <span className="d-inline-block text-truncate" style={{ maxWidth: '80px' }}>
                                                            {formatIdentifier(item.rutempleador)}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="d-inline-block text-truncate text-uppercase" style={{ maxWidth: '90px' }}>
                                                            {item.razonSocialempleador}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="d-inline-block text-truncate text-uppercase" style={{ maxWidth: '60px' }}>
                                                            {item.emailNotifiacionempleador}
                                                        </span>
                                                    </td>
                                                    <td className="d-none d-md-table-cell">
                                                        <span className="d-inline-block text-truncate" style={{ maxWidth: '60px' }}>
                                                            {item.telefonoempleador && item.telefonoempleador.length >= 9 ? item.telefonoempleador : ""}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="d-inline-block text-truncate text-uppercase" style={{ maxWidth: '60px' }}>
                                                            {item.direccionempleador}
                                                        </span>
                                                    </td>
                                                    <td className="d-none d-md-table-cell">
                                                        <span className="d-inline-block text-truncate text-uppercase" style={{ maxWidth: '60px' }}>
                                                            {item.nombreRegion}
                                                        </span>
                                                    </td>
                                                    <td className="d-none d-md-table-cell">
                                                        <span className="d-inline-block text-truncate text-uppercase" style={{ maxWidth: '60px' }}>
                                                            {item.nombreComuna}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="10" className="text-center">
                                                    Sin registros
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="d-sm-block d-lg-none">
                            <div className="list-view">
                                {empleadores && empleadores.length > 0 ? (
                                    empleadores.map((item, index) => {
                                        const rutEmpleador = formatIdentifier(item.rutempleador);
                                        const telefonoEmpleador = item.telefonoempleador && item.telefonoempleador.length >= 9 ? item.telefonoempleador : "";

                                        return (
                                            <div key={index} className={`list-item ${selected === item.id ? "selected" : ""}`}>
                                                <div className="list-header">
                                                    <div className="row mb-3">
                                                        <Form.Label className="col-sm-4 col-form-label">Rut</Form.Label>
                                                        <div className="col-sm-8">
                                                            <Form.Control type="text" className="form-control-plaintext" value={rutEmpleador} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <Form.Label className="col-sm-4 col-form-label">Razón social</Form.Label>
                                                        <div className="col-sm-8">
                                                            <Form.Control type="text" className="form-control-plaintext text-truncate text-uppercase" value={item.razonSocialempleador} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <Form.Label className="col-sm-4 col-form-label">Correo</Form.Label>
                                                        <div className="col-sm-8">
                                                            <Form.Control type="text" className="form-control-plaintext text-truncate text-uppercase" value={item.emailNotifiacionempleador} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <Form.Label className="col-sm-4 col-form-label">Teléfono</Form.Label>
                                                        <div className="col-sm-8">
                                                            <Form.Control type="text" className="form-control-plaintext text-truncate text-uppercase" value={telefonoEmpleador} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <Form.Label className="col-sm-4 col-form-label">Dirección</Form.Label>
                                                        <div className="col-sm-8">
                                                            <Form.Control type="text" className="form-control-plaintext text-truncate text-uppercase" value={item.direccionempleador} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <Form.Label className="col-sm-4 col-form-label">Región</Form.Label>
                                                        <div className="col-sm-8">
                                                            <Form.Control type="text" className="form-control-plaintext text-truncate text-uppercase" value={item.nombreRegion} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <Form.Label className="col-sm-4 col-form-label">Comuna</Form.Label>
                                                        <div className="col-sm-8">
                                                            <Form.Control type="text" className="form-control-plaintext text-truncate text-uppercase" value={item.nombreComuna} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="actions">
                                                        <a className="pointer" onClick={() => Editar(item)}>
                                                            <img src={images.edit} alt="Edit record" width="15" height="18" />
                                                        </a>
                                                        <a className="pointer" onClick={() => Eliminar(item)}>
                                                            <img src={images.erase} alt="Delete record" width="15" height="18" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })) : (
                                    <div className="list-item text-center">
                                        Sin registros
                                    </div>
                                )}
                            </div>
                        </div>

                        {message.length > 0 && (
                            <div className="alert alert-danger mt-3" role="alert">
                                <ul className="list-group p-3">
                                    {message.map((item, index) => (
                                        <li key={index}>
                                            <strong>{item.error}</strong>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </>
                )}

                {ConfigManager.SHOW_MARKUP && (
                    <div className="alert alert-danger mt-3" role="alert">
                        <pre>{JSON.stringify(empleadores, null, "\t")}</pre>
                    </div>
                )}
            </div>
        </div>
    );
}