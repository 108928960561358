import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { formatIdentifier } from "../../utils/helper/utils";

export default function DatosPersonales({ data }) {

    const [name, setName] = useState("");
    const [identifier, seIdentifier] = useState("");
    const [birthday, setBirthday] = useState("");
    const [birthplace, setBirthplace] = useState("");

    useEffect(() => {
        try {
            const name = `${data.Nombre} ${data.ApellidoPaterno} ${data.ApellidoMaterno}`;
            setName(name);
        } catch (error) {
            console.error('Error formatting name:', error);
        }
    }, [data.Nombre, data.ApellidoPaterno, data.ApellidoMaterno]);

    useEffect(() => {
        seIdentifier(formatIdentifier(data.Rut));
    }, [data.Rut]);

    useEffect(() => {
        try {
            const date = data.FechaNacimiento;

            if (date.length !== 8) {
                throw new Error('Invalid date format');
            }

            const formattedDate = `${date.substring(6, 8)}/${date.substring(4, 6)}/${date.substring(0, 4)}`;
            setBirthday(formattedDate);
        } catch (error) {
            console.error('Error formatting birthday:', error);
            setBirthday('Invalid date');
        }
    }, [data.FechaNacimiento]);

    useEffect(() => {
        try {
            const cadena = data.NombreNacionalidad;

            const primerCaracter = cadena.charAt(0);

            const restoDeLaCadena = cadena.substring(1, cadena.length);

            setBirthplace(primerCaracter.concat(restoDeLaCadena).replace(/\./g, ""));

        } catch (error) {
            console.error('Error formatting birthday:', error);
        }
    }, [data.NombreNacionalidad]);

    return (
        <fieldset>
            <div className="row mb-3">
                <Form.Label htmlFor="name" className="col-sm-4 col-form-label">Nombre</Form.Label>
                <div className="col-sm-8">
                    <Form.Control type="text" className="form-control-plaintext text-uppercase" id="name" value={name} readOnly />
                </div>
            </div>
            <div className="row mb-3">
                <Form.Label htmlFor="dni" className="col-sm-4 col-form-label">Rut</Form.Label >
                <div className="col-sm-8">
                    <Form.Control type="text" className="form-control-plaintext text-uppercase" id="dni" value={identifier} readOnly />
                </div>
            </div>
            <div className="row mb-3">
                <Form.Label htmlFor="birthday" className="col-sm-4 col-form-label">Fecha de nacimiento</Form.Label >
                <div className="col-sm-8">
                    <Form.Control type="text" className="form-control-plaintext text-uppercase" id="birthday" value={birthday} readOnly />
                </div>
            </div>
            <div className="row mb-3">
                <Form.Label htmlFor="birthplace" className="col-sm-4 col-form-label">Nacionalidad</Form.Label >
                <div className="col-sm-8">
                    <Form.Control type="text" className="form-control-plaintext text-uppercase" id="birthplace" value={birthplace} readOnly />
                </div>
            </div>
        </fieldset>
    );
}