
// Mapeo de regimenes
export const mapToRegimenes = (jsonArray: any[]): any[] => {
    if (jsonArray.length > 0) {
        return jsonArray.map(item => ({
            Codigo: item.codigo,
            Saldo: item.saldo,
            SaldoCuota: item.saldoCuota,
            ValorCuota: item.valorCuota
        }));
    }
    return [];
};

// Mapeo de fondos
export const mapToFondos = (jsonArray: any[], porcentaje: number): any[] => {
    if (jsonArray.length > 0) {
        return jsonArray.map(item => ({
            Porcentaje: porcentaje,
            Origen: item.origen,
            Destino: item.nombre,
            Saldo: item.saldo,
            saldoCuota: item.saldoCuota,
            Recaudador: item.fondoRecaudador,
            Regimenes: mapToRegimenes(item.regimenes)
        }));
    }
    return [];
};

// Mapeo de cuentas
export const mapToCuentas = (jsonArray: any[]): any[] => {
    if (jsonArray.length > 0) {
        return jsonArray.map(item => ({
            Tipo: item.tipo,
            CodigoTipo: item.codigotipo,
            Origen: item.origen,
            CodigoOrigen: item.codigoorigen,
            Porcentaje: parseInt(item.Porcentaje),
            SuscribeMandato: false,
            Fondos: mapToFondos(item.fondos, parseInt(item.Porcentaje))
        }));
    }
    return [];
};

// Mapeo de persona
export const mapToPersona = (json: any, autenticacion: string, device: string) => {
    return {
        Autenticacion: autenticacion,
        Device: device,
        Rut: json.Rut,
        Nombre: json.Nombre,
        ApellidoPaterno: json.ApellidoPaterno,
        ApellidoMaterno: json.ApellidoMaterno,
        FechaNacimiento: json.FechaNacimiento,
        Email: json.Email,
        Telefono: json.Telefono,
        Direccion: json.Direccion,
        Nacionalidad: json.Nacionalidad,
        NombreRegion: json.NombreRegion,
        Comuna: json.Comuna,
        NombreComuna: json.NombreComuna,
        Cartola: json.Cartola,
        Sexo: json.Sexo === "M" ? 1 : 2,
        TipoEmpleado: parseInt(json.TipoEmpleado),
        Prospecto: json.Prospecto,
        Empleadores: mapToEmpleadores(json.Empleadores, parseInt(json.TipoEmpleado))
    };
};

// Mapeo de empleadores
export const mapToEmpleadores = (jsonArray: any[], TipoEmpleado: number): any[] => {    
    if (TipoEmpleado !== 3 && TipoEmpleado !== 7) {
        if (jsonArray.length > 0) {
            return jsonArray.map(item => ({
                Rut: item.rutempleador,
                RazonSocial: item.razonSocialempleador,
                Email: item.emailNotifiacionempleador,
                Telefono: item.telefonoempleador,
                Direccion: item.direccionempleador,
                NombreRegion: item.nombreRegion,
                Comuna: item.comunaempleador,
                NombreComuna: item.nombreComuna
            }));
        }
    }
    return [];
};