import "./Timer.scss";
import { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import images from "../../assets/images";
import Swal from "sweetalert2";

export default function Timer() {

    const initialSeconds = 25 * 60;
    const intervalRef = useRef(null);
    const [redirect, setRedirect] = useState(false);
    const [variant, setVariant] = useState("success");
    const [timeLeft, setTimeLeft] = useState(() => {
        const savedSeconds = localStorage.getItem('sessionTime');
        return savedSeconds ? parseInt(savedSeconds, 10) : initialSeconds;
    });

    useEffect(() => {
        const updateTimer = () => {
            setTimeLeft(prevSeconds => {
                if (prevSeconds <= 1) {
                    clearInterval(intervalRef.current);
                    localStorage.removeItem('sessionTime');

                    const handleTimeout = async () => {
                        let timerInterval;
                        Swal.fire({
                            title: "Tiempo agotado",
                            html: `<img src=${images.timer} height="60" /></br></br><p>Tu sesión ha expirado y serás redirigido al inicio.</p>`,
                            timer: 10000,
                            timerProgressBar: true,
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading();
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((timerResult) => {
                            if (timerResult.dismiss === Swal.DismissReason.timer) {
                                setRedirect(true);
                            }
                        });
                    };

                    handleTimeout();
                }

                localStorage.setItem('sessionTime', prevSeconds - 1);
                return prevSeconds - 1;
            });
        };

        // Configurar el intervalo para que actualice cada segundo
        intervalRef.current = setInterval(updateTimer, 1000);

        // Limpiar el intervalo cuando el componente se desmonte
        return () => clearInterval(intervalRef.current);
    }, [initialSeconds]);

    const formatTime = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60).toString().padStart(2, '0');
        const seconds = (totalSeconds % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    useEffect(() => {
        if (timeLeft >= 301 && timeLeft <= 600) { // 10 minutos
            setVariant("warning");
        } else if (timeLeft <= 300) { // 5 minutos
            setVariant("danger");
        }
    }, [timeLeft]);

    if (redirect) {
        return <Redirect to={{ pathname: "/", state: { message: "Su sesión ha caducado y ha sido redirigido a la página de inicio." } }} />;
    }

    return (
        <>
            <div className={`container-timer container-timer-${variant}`}>
                {formatTime(timeLeft)} <img src={images.timer} width="30" alt="Clock" />
            </div>
        </>
    );
}