import { useState } from "react";
import { Button, Card, Accordion } from "react-bootstrap";
import images from "../../assets/images";
import Boxes from "./Boxes";

export default function SabiasInfo() {
    const [toggleState, setToggleState] = useState("off");

    const data = [
        {
            "fondo": "A",
            "riesgo": "Más Riesgoso",
            "detalle": "Permite rentabilizar los ahorros a largo plazo, y la inversión principalmente se enfoca en instrumentos de renta variable, los rangos permitidos de inversión en renta variable son: -Máximo permitido 80% -Mínimo obligatorio 40%",
            "color": "#EE0202"
        },
        {
            "fondo": "B",
            "riesgo": "Riesgoso",
            "detalle": "Se compone de instrumentos de renta variable, siendo menor la distribución, pensado en rentabilidades a largo y mediano plazo. Los rangos permitidos de inversión de renta variable son: -Máximo permitido 60%  -Mínimo obligatorio 25%",
            "color": "#FFC145"
        },
        {
            "fondo": "C",
            "riesgo": "Intermedio",
            "detalle": "La distribución de las inversiones se da equilibrada entre instrumentos de renta variable y renta fija. Los rangos permitidos de inversión en renta variable son: -Máximo permitido 40% -Mínimo obligatorio 15%",
            "color": "#652F07"
        },
        {
            "fondo": "D",
            "riesgo": "Intermedio",
            "detalle": "Recomendado para comenzar a resguardar los fondos, cerca de la edad de pensión, los instrumentos para inversión son principalmente en renta fija. Los rangos permitidos de inversión en renta variable son: -Máximo permitido 20% -Mínimo obligatorio 5%",
            "color": "#30A7A7"
        },
        {
            "fondo": "E",
            "riesgo": "Más Conservador",
            "detalle": "Recomendado para resguardar los fondos antes de iniciar el proceso de pensión, es un fondo estable en cuanto al riesgo y baja rentabilidad. Los rangos permitidos de inversión en renta variable son: -Entre 0% y 5% En instrumentos de renta fija: -Entre 95% y 100%",
            "color": "#81BD00"
        }
    ];

    function toggle() {
        setToggleState(toggleState === "off" ? "on" : "off");
    }

    return (
        <div className="mt-3 mb-3">
            <Accordion>
                <Card>
                    <Card.Header>
                        <div className="row">
                            <div className="col-lg-2 text-center d-none d-lg-block">
                                <img width={50} src={images.question} alt="pregunta" />
                            </div>
                            <div className="col-sm-10 col-md-9 col-lg-7">
                                <div className="m-2">
                                    ¿Sabías qué?
                                </div>
                                <div className="m-2">
                                    puedes cambiar tus fondos cuando cambias de AFP.
                                </div>
                            </div>
                            <div className="col-sm-2 col-md-3 col-lg-3 text-center">
                                <Accordion.Toggle as={Button} onClick={toggle} variant="link" eventKey="0" className="mr-4">
                                    {toggleState === "off" ? <p>Ver más</p> : <p>Ver menos</p>}
                                </Accordion.Toggle>
                            </div>
                        </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body >
                            <Boxes data={data} />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}