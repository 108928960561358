import FloatingWhatsApp from "react-floating-whatsapp";
import configuration from "../../settings/ConfigManager";
import images from "../../assets/images";

export default function Whatsapp() {
	return (
		<FloatingWhatsApp
			phoneNumber={configuration.WSP_NUM}
			accountName="AFP Modelo"
			height={300}
			avatar={images.chat}
			statusMessage="En Línea"
			chatMessage="Hola!! ¿necesitas ayuda?"
			darkMode={true}
			allowClickAway={true}
			allowEsc={true}
			placeholder="Escribe tu mensaje"
			notification={false}
			notificationDelay={30000}
			notificationSound={false}
		/>
	);
}
