import "./Header.scss";
import { Nav, Navbar, NavDropdown, Container } from "react-bootstrap";
import { Avatar } from "@mui/material";

interface NameProps {
	name: string;
	iniciales: string
}

const Header: React.FC<NameProps> = (props) => {
	const handleClose = () => {
		sessionStorage.clear();
		localStorage.clear();
		window.location.replace("/");
	};

	return (
		<>
			<Navbar variant="dark" data-bs-theme="dark" /*bg="dark"*/ sticky="top">
				<Container>
					<Navbar.Brand href="https://nueva.afpmodelo.cl/afiliados" target="_blank">
						<div className="logo"></div>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav" className="offset-10 col-2 justify-content-end">
						<Navbar.Text></Navbar.Text>
						{props.iniciales && (
							<Nav className="me-auto d-none d-lg-flex">
								<Nav.Link>
									<Avatar className="color-avatar">{props.iniciales}</Avatar>
								</Nav.Link>
								<NavDropdown title={props.name} id="basic-nav-dropdown">
									{/*<NavDropdown.Divider />*/}
									<NavDropdown.Item onClick={handleClose}>Cerrar</NavDropdown.Item>
								</NavDropdown>
							</Nav>
						)}
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	);
}

export default Header;