import { getImage } from "../../utils/helper/utils";

export default function Fondos({ esVisible, data, seleccionado, elegibles, index, onCallback }) {
    return (
        <div className="row">
            {esVisible && (
                <div className="modal-content text-center" style={{ border: "none", fontSize: "10px" }}>
                    <div style={{ display: "flex", justifyContent: "space-around", textAlign: "center", flexWrap: "wrap" }}>
                        {data.map((item) => {
                            const image = getImage(item.fondo, seleccionado === item.fondo);
                            const isEligible = elegibles[item.fondo];
                            const uniqueKey = `${item.fondo}-${index}`;

                            return (
                                <span
                                    key={uniqueKey}
                                    onClick={() => { isEligible && onCallback(item.fondo, index) }}
                                    style={{ cursor: isEligible ? "pointer" : "not-allowed", width: "85px" }}
                                >
                                    <div>
                                        <img src={image} width="39" height="39" alt={item.fondo} />
                                    </div>
                                    <div>
                                        <p className="estado">{item.descripcion}</p>
                                    </div>
                                </span>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}
