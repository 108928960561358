import React from "react";
import { Button as ReactButton } from "react-bootstrap";

const Loader = () => (
    <>
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span className="no-visually-hidden">Cargando... </span>
    </>
);

const Button = React.memo(({ id, type, variant, disabled, loading, onClick, size, block, children }) => {

    const containerClassName = block ? "d-grid gap-2" : "";

    return (
        <div className={containerClassName} >
            <ReactButton
                id={id}
                type={type}
                variant={variant}
                disabled={disabled}
                onClick={onClick}
                size={size}
            >
                {loading ? <Loader /> : children}
            </ReactButton>
        </div>
    );
});

export default Button;
