import "./Disclaimer.scss";
import DisclaimerItem from "./Item";
import disclaimer from "../../utils/markup/disclaimer";
import images from "../../assets/images";

export default function Disclaimer() {
    return (
        <div className="container-disclaimer">
            <div className="header">
                <div className="row">
                    <div className="col-10">
                        <h5 className="text">¿Por qué cambiarme a AFP Modelo?</h5>
                    </div>
                    <div className="col-2">
                        <img src={images.face} alt="face" />
                    </div>
                </div>
            </div>
            <div className="body">
                {disclaimer.highlights.map((item) => (
                    <DisclaimerItem data={item} image={item.image} />
                ))}
            </div>
        </div>
    )
}