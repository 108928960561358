import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext } from "react";
import Main from "./components/Main/Main";
import Context from "./context/context";
import Loading from "./components/Loading/Loading";
import Whatsapp from "./components/Whatsapp/Whatsapp";

export const userContext = React.createContext();

function App() {
	const { state } = useContext(Context);

	return (
		<div id="app-container">
			{state.isLoading && (<Loading />)}
			<Main></Main>
			<Whatsapp />
		</div>
	);
}

export default App;
